@import "../../variables";
.social-icon {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 30px;
    background-color: $social-icon-color;
    cursor: pointer;
    transition: all 0.2s ease-in;
    &:hover {
        background-color: $dark;
    }
    img {
        width: 100%;
        height: auto;
    }
}