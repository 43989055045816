@import "../../variables";
form {
    position: relative;
    label {
        position: relative;
        width: 100%;
        margin-bottom: 25px;
        input {
            width: 100%;
            padding: $input-p;
            border-radius: 10px;
            border: 1px solid $border-color;
            font-size: 16px;
            transition: all 0.1s linear;
            &:active {
                color: $dark;
                background-color: $white !important;
            }
            &:hover {
                box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5) !important;
            }
            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1.0) !important;
            }
            &:focus-visible {
                outline: none;
                box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1.0) !important;
            }
        }
    }
    .image-wrapper {
        position: absolute;
        width: 22px;
        height: 22px;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }
}