@import "../../variables";
.liked-products {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    h3 {
        font-size: 1.5rem;
        line-height: 1.4em;
        margin: 0;
        color: $font-color;
    }
    .gift-events-container {
        padding: 15px 0 20px 0;
        background-color: $search-bg;
        .gift-heading {
            margin: 20px 60px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $border-color;
            padding-bottom: 15px;
            h3 {
                padding-top: 10px;
            }
            .image-wrapper {
                width: 40px;
                height: 35px;
                //margin-right: 10px;
            }
            @media (max-width: 1399.98px) {
                margin: 15px 40px 30px 40px;
            }
            @media (max-width: 991.98px) {
                margin: 15px 30px;
            }
        }
        .gift-events-list {
            margin: auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 15px;
            column-gap: 15px;
            grid-row-gap: 15px;
            row-gap: 15px;
            width: 70%;
            .event-item-container {
                margin: 0;
                max-width: 185px;
                width: 100%;
                &:nth-child(odd) {
                    margin-left: auto;
                    margin-right: 0;
                }
                .image-wrapper {
                    $size: 120px;
                    margin: 0;
                    width: $size;
                    height: $size;
                    border-radius: $size;
                }
                .radio-group {
                    .radio-button {
                        display: block;
                    }
                    label {
                        margin-bottom: 0;
                        font-size: 18px;
                        font-weight: 500;
                        color: $font-color;
                    }
                }
                span {
                    color: #a4a5aa;
                    font-size: 0.875rem;
                }
                .delete-button {
                    display: none;
                }
                .edit-button {
                    display: none;
                }
                @media (max-width: 1399.98px) {
                    max-width: 120px;
                    width: 100%;
                    .image-wrapper {
                        $size: 120px;
                        margin: 0;
                        width: $size;
                        height: $size;
                        border-radius: $size;
                    }
                }
                @media (max-width: 991.98px) {
                    max-width: 100px;
                    width: 100%;
                    .image-wrapper {
                        $size: 100px;
                        margin: 0;
                        width: $size;
                        height: $size;
                        border-radius: $size;
                    }
                }
                @media (max-width: 575.98px) {
                    max-width: 175px;
                    width: 100%;
                }
            }
            @media (max-width: 1399.98px) {
                width: 100%;
            }
            @media (max-width: 991.98px) {
                grid-template-columns: 1fr 1fr;
            }
            @media (max-width: 767.98px) {
                width: 80%;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                .event-item-container {
                    margin-left: auto;
                    margin-right: auto !important;
                }
            }
            @media (max-width: 575.98px) {
                width: 70%;
                grid-template-columns: 1fr 1fr;
            }
        }
        @media (max-width: 991.98px) {
            padding: 0 0 15px 0;
        }
    }
    @media (max-width: 767.98px) {
        border-top: 1px solid transparent;
    }
    .products-container {
        padding: 15px 30px 20px 30px !important;
        h3 {
            margin: 30px 0 15px 0;
            padding-bottom: 15px;
            font-size: 1.5rem;
            border-bottom: 1px solid transparent;
            @media (max-width: 991.98px) {
                margin: 30px 0 0 0;
            }
        }
        .products-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 20px;
            column-gap: 20px;
            grid-row-gap: 20px;
            row-gap: 20px;
            @media (max-width: 1399.98px) {
                .product-card-container {
                    .image-wrapper {
                        height: 280px;
                    }
                }
            }
            @media (max-width: 1199.98px) {
                grid-template-columns: 1fr 1fr 1fr;
                .product-card-container {
                    .image-wrapper {
                        height: 260px;
                    }
                }
            }
            @media (max-width: 991.98px) {
                grid-template-columns: 1fr 1fr;
            }
            @media (max-width: 575.98px) {
                grid-template-columns: 1fr;
                .product-card-container {
                    .image-wrapper {
                        height: 300px;
                    }
                }
            }
        }
        @media (max-width: 991.98px) {
            padding: 0 30px 20px 30px !important;
        }
    }
    .policy-container {
        padding: 0 0;
    }
}