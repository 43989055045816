@import "../../../variables";
.modal-content {
    position: relative;
}

.go_to_profile {
    text-align: center;
    margin-bottom: 0px;
}

.btn-close {
    z-index: 2;
}

.profile-created-modal {
    display: none;
    margin: 0 auto;
    padding: 15px 0;
    cursor: default;
    @media (max-width: 1199.98px) {
        padding: 0;
    }
    &.active {
        display: block;
    }
    h5 {
        margin: 40px 0 15px 0;
        font-size: 18px;
        font-weight: normal;
        vertical-align: middle;
        span {
            margin-right: 2px;
            padding: 6px;
            border: 1px solid #E3E3E3;
            border-radius: 30px;
            font-size: 14px;
            vertical-align: 1px;
            color: $font-secondary-color;
        }
    }
    p {
        // width: $modal-body-width;
        @media (max-width: 991.98px) {
            width: 100%;
        }
    }
    .progress-line {
        .progress-point {
            &:nth-child(1) {
                background-color: $progress-checked-color;
                border-color: $progress-checked-color;
                img {
                    display: block;
                }
            }
            &:nth-child(2) {
                background-color: $progress-checked-color;
                border-color: $progress-checked-color;
                img {
                    display: block;
                }
            }
            &:nth-child(3) {
                background-color: $progress-checked-color;
                border-color: $progress-checked-color;
                img {
                    display: block;
                }
            }
            &:nth-child(4) {
                background-color: $progress-checked-color;
                border-color: $progress-checked-color;
                img {
                    display: block;
                }
            }
            &:nth-child(5) {
                background-color: $progress-checked-color;
                border-color: $progress-checked-color;
                img {
                    display: block;
                }
            }
        }
    }
    .header-container {
        margin-top: 3rem;
        max-width: 400px !important;
        h1 {
            margin: 0 auto 1rem auto !important;
        }
        p {
            margin: 0 auto !important;
        }
    }
    .go-to-profile-wrapper {
        margin: 0 auto 3rem auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 210px;
        .go-to-profile-button {
            padding: 0;
            background-color: transparent;
            z-index: 2;
            span {
                text-transform: none;
                font-weight: 600;
                font-size: 1.25rem;
                color: $font-color;
            }
            img {
                filter: invert(82%) sepia(67%) saturate(4400%) hue-rotate(717deg) brightness(90%) contrast(72%);
            }
        }
        .avatar-container {
            padding-bottom: 0;
            span {
                display: none;
            }
            .avatar-image-wrapper {
                @media (max-width: 991.98px) {
                    width: 120px;
                    height: 120px;
                }
            }
        }
    }
    .image-wrapper {
        position: absolute;
        top: -40px;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: default;
        z-index: 0;
        img {
            top: -25%;
            left: 0;
            animation: confettiAnimation 1.5s forwards;
        }
        // @media (max-width: 1199.98px) {
        //     top: -70px;
        // }
        // @media (max-width: 991.98px) {
        //     top: -105px;
        // }
        // @media (max-width: 767.98px) {
        //     top: -105px;
        // }
        // @media (max-width: 575.98px) {
        //     top: -170px;
        // }
    }
    @keyframes confettiAnimation {
        0% {
            top: -50%;
            opacity: 1;
            transform: scale(1);
        }
        50% {
            opacity: 1;
        }
        100% {
            top: 0%;
            opacity: 0;
            transform: scale(1.15);
        }
    }
    .header-container {
        max-width: 100%;
        position: relative;
        z-index: 1;
        h1,
        p {
            margin: 0 auto 30px auto;
            max-width: $form-group-width;
        }
    }
    //TODO: delete 
    >a {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: #0d6efd;
        cursor: pointer;
        z-index: 999;
    }
}