@import "../../variables";
.image-wrapper {
    cursor: pointer;
    .upload-button-label {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 140px;
        min-height: 140px;
        border-radius: 140px;
        overflow: hidden;
        cursor: pointer;
        z-index: 1;
        img {
            max-width: 100%;
            max-height: 100%;
            background-color: $white;
        }
    }
    .initials-view {
        // width: 100%;
        // height: 100%;
        &.none {
            display: none;
        }
    }
}