@import "../../../variables";
.try-modal {
    display: none;
    margin: 0 auto;
    padding: 15px 0 30px 0;
    max-width: $modal-body-width;
    p {
        font-size: 18px;
        a {
            text-decoration: underline;
        }
    }
    .button {
        margin-bottom: 30px;
    }
    &.active {
        display: block;
    }
}