@import "../../variables";
.take-the-tour-container {
    overflow: hidden;
    &.step-оne-аnimation {
        .step-one {
            .line-path {
                &::after {
                    animation: stepOnelineAfter 1.5s linear forwards;
                }
            }
        }
        .step-two {
            .line-path {
                &::before {
                    animation: stepTwolineBefore 0.5s forwards;
                    animation-delay: 1.5s;
                }
            }
        }
    }
    .step-one {
        background-image: url(../../assets/images/images-sm/tour-bg1.png);
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        .row {
            min-height: 845px;
        }
        .button {
            display: none;
        }
        .line-path {
            &::before {
                content: none;
            }
            &::after {
                top: 295px;
                right: -45px;
                width: 40%;
                opacity: 0;
            }
            @media (max-width: 1399.98px) {
                &::after {
                    min-width: 42%;
                }
            }
            @media (max-width: 1199.98px) {
                &::after {
                    top: 180px;
                    right: -35px;
                    max-width: 15%;
                    min-width: auto;
                    max-height: 70%;
                }
            }
            @media (max-width: 991.98px) {
                &::after {
                    top: 170px;
                    max-width: 20%;
                    max-height: 72%;
                }
            }
            @media (max-width: 767.98px) {
                &::after {
                    top: 105px;
                    max-width: 8%;
                    max-height: 87%;
                }
            }
            @media (max-width: 575.98px) {
                &::after {
                    top: 92px;
                    max-width: 10%;
                }
            }
        }
    }
    .step-two {
        background-image: url(../../assets/images/images-sm/tour-bg2.png);
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $search-bg;
        .button {
            display: none;
        }
        .tour-container {
            .row {
                flex-direction: row-reverse;
                .tour-content-wrapper {
                    flex-direction: row-reverse;
                }
            }
        }
        .line-path {
            &::before {
                top: 70px;
                left: -45px;
                max-height: 80px;
                opacity: 0;
            }
            &::after {
                top: 299px;
                right: auto;
                left: -45px;
                width: 40%;
                max-height: 95%;
                border-right: none;
                border-left: 3px solid $border-color;
                opacity: 0;
            }
            @media (max-width: 1399.98px) {
                &::after {
                    min-width: 42%;
                }
            }
            @media (max-width: 1199.98px) {
                &::before {
                    top: 24px;
                    left: -37px;
                    max-height: 30px;
                }
                &::after {
                    top: 168px;
                    left: -35px;
                    min-width: auto;
                    max-width: 14%;
                    max-height: 77%;
                }
            }
            @media (max-width: 991.98px) {
                &::after {
                    top: 145px;
                    max-width: 19%;
                    max-height: 81%;
                }
            }
            @media (max-width: 767.98px) {
                &::before {
                    content: none;
                }
                &::after {
                    top: 80px;
                    max-width: 8%;
                    max-height: 100%;
                }
            }
            @media (max-width: 575.98px) {
                &::after {
                    top: 68px;
                    max-width: 10%;
                    max-height: 95%;
                }
            }
        }
        &.step-two-аnimation {
            .line-path {
                &::after {
                    animation: stepTwolineAfter 1.5s forwards;
                    animation-delay: 0s;
                }
            }
        }
    }
    .step-three {
        background-image: url(../../assets/images/images-sm/tour-bg3.png);
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        .line-path {
            &::before {
                top: -18px;
                right: -45px;
                opacity: 0;
            }
            &::after {
                content: none;
            }
        }
        &.step-three-аnimation {
            .line-path {
                &::before {
                    animation: stepThreelineBefore 0.5s forwards;
                    animation-delay: 1.5s;
                    max-height: 190px;
                }
                @media (max-width: 1199.98px) {
                    &::before {
                        top: 41px;
                        right: -34px;
                        max-height: 30px;
                    }
                }
                @media (max-width: 991.98px) {
                    &::before {
                        top: 34px;
                        max-height: 37px;
                    }
                }
                @media (max-width: 767.98px) {
                    &::before {
                        content: none;
                    }
                }
            }
        }
        .tour-container {
            .tour-content-wrapper {
                .decoration {
                    .tour-step {
                        cursor: default;
                    }
                }
            }
        }
    }
}

//Step One
@keyframes stepOnelineAfter {
    0% {
        width: 0%;
        height: 0%;
        opacity: 0;
    }
    5% {
        width: 0%;
        height: 0%;
        opacity: 1;
    }
    70% {
        width: 0%;
        height: 100%;
        opacity: 1;
    }
    100% {
        width: 40%;
        height: 100%;
        opacity: 1;
    }
}

//Step Two
@keyframes stepTwolineBefore {
    0% {
        height: 0%;
        opacity: 0;
    }
    5% {
        height: 0%;
        opacity: 1;
    }
    100% {
        height: 100%;
        opacity: 1;
    }
}

@keyframes stepTwolineAfter {
    0% {
        width: 0%;
        height: 0%;
        opacity: 0;
    }
    5% {
        width: 0%;
        height: 0%;
        opacity: 1;
    }
    70% {
        width: 0%;
        height: 100%;
        opacity: 1;
    }
    100% {
        width: 40%;
        height: 100%;
        opacity: 1;
    }
}

//Step Three
@keyframes stepThreelineBefore {
    0% {
        height: 0%;
        opacity: 0;
    }
    5% {
        height: 0%;
        opacity: 1;
    }
    100% {
        height: 100%;
        opacity: 1;
    }
}
