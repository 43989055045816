@import "../../../variables";
$form-width: 270px;
.event-modal {
    display: none;
    margin: 0 auto;
    padding: 15px 0 30px 0;
    max-width: $form-group-width;
    &.active {
        display: block;
    }
    .progress-line {
        .progress-point {
            &:nth-child(1) {
                background-color: $progress-checked-color;
                border-color: $progress-checked-color;
                img {
                    display: block;
                }
            }
            &:nth-child(2) {
                background-color: $progress-point-color;
                border-color: $progress-point-color;
            }
        }
    }
    .header-container {
        margin: 0 auto;
        max-width: $modal-body-width;
    }
    .event-wrapper {
        margin: 0 auto;
        width: 60%;
        @media (max-width: 1199.98px) {
            width: 60%;
        }
        @media (max-width: 991.98px) {
            width: 70%;
        }
        @media (max-width: 767.98px) {
            width: 80%;
        }
        @media (max-width: 575.98px) {
            width: 100%;
        }
        .event-container {
            margin: 30px 0 0 0;
            .col {
                &:nth-child(1) {
                    img {
                        width: calc(100% + 42px);
                        top: calc(50% - 0px);
                        left: calc(50% + 3px);
                    }
                }
                &:nth-child(2) {
                    img {
                        width: calc(100% + 42px);
                        top: calc(50%);
                        left: calc(50% + 3px);
                    }
                }
                &:nth-child(2) {
                    img {
                        width: calc(100% + 90px);
                        top: calc(50% - 15px);
                        left: calc(50% - 30px);
                    }
                }
                &:nth-child(3) {
                    img {
                        width: calc(100% + 42px);
                        top: calc(50%);
                        left: calc(50%);
                    }
                }
                &:nth-child(4) {
                    img {
                        width: calc(100% + 65px);
                        top: calc(50% - 2px);
                        left: calc(50% + 18px);
                    }
                }
                &:nth-child(5) {
                    img {
                        width: calc(100% + 100px);
                        top: calc(50% - 15px);
                        left: calc(50% - 45px);
                    }
                }
            }
        }
    }
    .budget-container {
        margin: 30px auto 15px auto;
        width: $form-width;
        @media (max-width: 991.98px) {
            padding: 0;
            // width: 100%;
        }
        h5 {
            margin: 15px 0;
            font-size: 1.25rem;
            font-weight: bold;
            text-align: center;
        }
        p {
            font-size: 14px;
        }
        form {
            margin: auto;
            position: relative;
            label {
                margin-bottom: 15px;
            }
            input {
                padding: 15px 15px 15px 40px;
                position: relative;
            }
            &::before {
                content: "$";
                position: absolute;
                top: 16px;
                left: 30px;
                font-size: 16px;
                color: #A4A5AA;
                z-index: 1;
                @media (max-width: 991.98px) {
                    padding: 0;
                    left: 12px;
                }
            }
        }
    }
    .birthday-container {
        margin-bottom: 30px;
        @media (max-width: 991.98px) {
            padding: 0;
        }
        &.hidden {
            display: none;
        }
    }
    .button {
        opacity: 1; //TODO: Add className -> .disabled
        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }
}