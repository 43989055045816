@import "../../variables";
.products-container {
    padding-top: 30px !important;
    background-color: $white;
    .placeholder-image {
        margin: 30px 0;
        .image-wrapper {
            height: 200px;
            width: 185px;
            margin: auto;
        }
    }
}