@import "../../_variables.scss";
$checkbox-size: 28px;
.checkbox-container {
    margin-bottom: 12px;
    padding-left: $checkbox-size + 10px;
    display: block;
    position: relative;
    min-height: $checkbox-size;
    font-size: 18px;
    line-height: 1.6em;
    color: $p-color;
    cursor: pointer;
    user-select: none;
    input {
        position: absolute;
        cursor: pointer;
        height: 0;
        width: 0;
        opacity: 0;
    }
    &:hover {
        input~.checkmark {
            border-color: $button-primary;
        }
    }
    input:checked~.checkmark {
        color: $button-primary;
        border-color: $button-primary;
        background-color: $white;
    }
    input:checked~.checkmark:after {
        display: block;
    }
    .checkmark {
        &::after {
            left: 5px;
            top: 6px;
            width: 100%;
            height: $checkbox-size/2;
            background-image: url(../../assets/icons/checking-mark-red.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: $checkbox-size;
    width: $checkbox-size;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 6px;
    transition: all 0.2s linear;
    &::after {
        content: "";
        position: absolute;
        display: none;
    }
}