@import "../../../variables";
$tab-width: 375px;
.react-tabs {
    display: flex;
    width: 100%;
    height: calc(100vh - 98px);
    color: white;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    .react-tabs__tab-list {
        margin: 0;
        padding: 60px 0 0 15px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        width: 50%;
        height: 100%;
        background-color: $search-bg;
        border-right: 1px solid $border-color;
        .settings-profile-container {
            margin: 0;
            padding: 0 15px 50px 15px;
            display: flex;
            width: 100%;
            max-width: $tab-width;
            .person-image-group {
                .image-wrapper {
                    &:last-of-type {
                        display: none;
                    }
                }
            }
            .profile-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: $font-color;
                h3 {
                    font-size: 26px;
                    word-wrap: break-word;
                    word-break: break-word;
                }
                .rapport-score {
                    display: flex;
                    align-items: center;
                    .rapport-counter {
                        margin: 0 12px 0 0;
                        padding: 5px 0 0 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 49px;
                        height: 49px;
                        background-color: #FFF6D2;
                        border: 1px solid #DBC56D;
                        border-radius: 60px;
                        font-family: 'Spartan', sans-serif;
                        font-weight: bold;
                        font-size: 19px;
                    }
                    span {
                        padding: 5px 0 0 0;
                        font-family: 'Spartan', sans-serif;
                        font-weight: bold;
                        font-size: 12px;
                        text-transform: uppercase;
                        color: #6D6D72;
                    }
                }
            }
        }
        .react-tabs__tab {
            margin: -1px -1px 0 0;
            padding: 0 0 0 25px;
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            max-width: $tab-width;
            height: 65px;
            list-style: none;
            cursor: pointer;
            border-bottom: none;
            &::before {
                display: block;
                content: "";
                position: absolute;
                width: calc(100% - 70px);
                bottom: 0;
                border-bottom: 1px solid $border-color;
            }
            span {
                font-size: 15px;
                font-weight: 700;
                color: $grey;
                text-transform: uppercase;
            }
            .image-wrapper {
                margin-right: 12px;
                width: 20px;
                height: 100%;
            }
            &:hover {
                span {
                    transition: color 0.2s ease-in-out;
                    color: $dark;
                }
                .image-wrapper {
                    img {
                        transition: filter 0.2s ease-in-out;
                        filter: brightness(0);
                    }
                }
            }
        }
        .react-tabs__tab--selected {
            position: relative;
            background-color: $white;
            border: 1px solid $border-color;
            border-right: 1px solid $white;
            border-top-left-radius: 18px;
            border-bottom-left-radius: 18px;
            transition: background-color 0.2s ease-in;
            &::before {
                display: none;
            }
            &::after {
                display: block;
                content: "";
                position: absolute;
                right: 20px;
                width: 10px;
                height: 10px;
                border-top: 3px solid $dark;
                border-left: 3px solid $dark;
                transform: rotate(135deg);
                z-index: 11;
            }
            span {
                transition: color 0.2s ease-in;
                color: $dark;
            }
            .image-wrapper {
                img {
                    transition: filter 0.2s ease-in;
                    filter: brightness(0);
                }
            }
        }
    }
    .react-tabs__tab-panel {
        display: none;
        width: 100%;
        padding: 30px 60px;
        p {
            text-align: left;
            color: $p-color;
        }
        .panel-content {
            margin: 125px 0 0 0;
            text-align: left;
            max-width: 800px;
            width: 100%;
            h2 {
                margin-bottom: 80px;
            }
            .deactivate-container {
                margin: 60px 0;
                display: flex;
                .image-wrapper {
                    margin-right: 30px;
                    width: 157px;
                    height: 157px;
                    img {
                        filter: opacity(30%);
                    }
                }
                &.lock-icon img {
                    width: 70%;
                }
                .deactivate-button-container {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: column;
                    color: $dark;
                    font-size: 22px;
                    font-weight: bold;
                    span {
                        margin-bottom: 15px;
                        width: 100%;
                    }
                    .button {
                        margin: 0;
                        max-height: 64px;
                        font-size: 16px;
                        background-color: $button-primary;
                        span {
                            margin: 0;
                        }
                        img {
                            display: none;
                        }
                        &:hover {
                            background-color: $button-primary-hover;
                        }
                    }
                }
            }
        }
    }
    .react-tabs__tab-panel--selected {
        display: block;
    }
    @media (max-width: 1199.98px) {
        .react-tabs__tab-list {
            .settings-profile-container {
                .person-image-group {
                    .image-wrapper {
                        width: 120px;
                        height: 120px;
                    }
                }
            }
        }
        .react-tabs__tab-panel {
            padding: 30px;
            .panel-content {
                margin: 15px 0 0 0;
                h2 {
                    margin-bottom: 30px;
                    font-size: 42px;
                }
            }
        }
    }
    @media (max-width: 767.98px) {
        flex-direction: column;
        height: 100%;
        .react-tabs__tab-list {
            padding: 15px 15px 30px 15px;
            width: 100%;
            .settings-profile-container {
                padding: 0 0 30px 0;
                flex-direction: column;
                max-width: 100%;
                .person-image-group {
                    display: flex;
                    justify-content: center;
                    .image-wrapper {
                        margin: 0 0 15px 0;
                    }
                }
                .profile-info {
                    h3 {
                        text-align: center;
                    }
                    .rapport-score {
                        justify-content: center;
                    }
                }
            }
            .react-tabs__tab {
                margin: -1px auto 0 auto;
                &::before {
                    width: calc(100% - 55px);
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            .react-tabs__tab--selected {
                border-radius: 18px;
                border: 1px solid $border-color;
            }
        }
        .react-tabs__tab-panel {
            padding: 30px 15px;
            .panel-content {
                margin: 15px 0 0 0;
                h2 {
                    margin-bottom: 30px;
                    text-align: center;
                    font-size: 36px;
                }
                p {
                    text-align: center;
                }
                .deactivate-container {
                    margin: 30px 0;
                    flex-direction: column;
                    align-items: center;
                    .image-wrapper {
                        margin: 0 0 15px 0;
                        width: 100px;
                        height: 100px;
                    }
                    .deactivate-button-container {
                        text-align: center;
                        .button {
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}