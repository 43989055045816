    @import "../../variables";
    .admin-navbar {
        display: none;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 20px 0;
        background-color: $font-color;
        .logo {
            margin: auto 60px auto 20px;
            display: flex;
            align-items: center;
        }
        .menu-icon {
            margin: auto 20px auto 0;
            padding-right: 40px;
            position: relative;
            color: $white;
            font-weight: 500;
            cursor: pointer;
            text-transform: uppercase;
            transition: color 0.2s;
            .plus {
                position: absolute;
                right: 0;
                width: 23px;
                height: 23px;
                cursor: pointer;
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    background-color: $white;
                    transition: transform 0.25s ease-out;
                }
                &:before {
                    top: 0;
                    left: 50%;
                    width: 3px;
                    height: 100%;
                    margin-left: -2px;
                }
                &:after {
                    top: 50%;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    margin-top: -2px;
                }
            }
            &:hover {
                color: rgba($color: #fff, $alpha: 0.5);
            }
        }
        .nav-admin-list {
            display: flex;
            max-height: 0;
            transition: max-height .2s ease-out;
            overflow: hidden;
            .nav-item {
                margin: 15px 0;
                padding: 0 30px;
                color: $white;
                text-transform: uppercase;
                text-decoration: none;
                text-align: center;
                &:hover {
                    color: rgba($color: #fff, $alpha: 0.5);
                }
            }
        }
        &.open-list {
            .nav-admin-list {
                max-height: 100%;
                flex-direction: column;
                overflow: visible;
                background-color: $font-color;
            }
            .menu-icon {
                .plus {
                    &:before {
                        transform: rotate(90deg);
                    }
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        @media (min-width: 1199.98px) {
            .menu-icon {
                display: none;
            }
            .nav-admin-list {
                max-height: none;
            }
            .menu-admin-icon {
                display: none;
            }
        }
        @media (max-width: 1199.98px) {
            justify-content: space-between;
            .nav-admin-list {
                width: 100%;
                flex-direction: column;
                .nav-item {
                    margin: 0 0 15px 0;
                    &:first-child {
                        margin-top: 30px;
                    }
                }
            }
        }
    }