@import "../../variables";
textarea {
    padding: 15px;
    max-width: 840px;
    width: 100%;
    border: 1px solid $border-color;
    border-radius: 10px;
    &:active {
        color: $dark;
        background-color: $white;
    }
    &:hover {
        box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5);
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1);
    }
    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1);
    }
}