@import "../../../variables";
.external-contacts-container {
    margin-top: 30px;
    h4 {
        text-align: center;
        color: $font-color;
    }
    .person-search-result-container {
        padding: 20px 0 15px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .person-info-container {
            margin: 0;
            padding: 0;
            .person-info {
                padding: 0 15px;
                h3 {
                    font-size: 1.25rem;
                }
                p {
                    word-break: break-all;
                }
            }
        }
        @media (max-width: 575.98px) {
            padding: 15px 15px 10px 15px !important;
            .person-info-container {
                .person-info {
                    padding: 0 15px 0 0;
                }
            }
        }
    }
    .button {
        margin: 0 0 0 auto;
        width: 120px;
        max-height: 56px;
    }
}