@import "../../variables";
.profile-container {
    .profile-info-container {
        display: flex;
        flex-wrap: nowrap !important;
        align-items: flex-start;
        // .person-image-group {
        //     margin: 0;
        // }
        .profile-info {
            h3 {
                margin: 0;
                width: 100%;
                font-size: 1.75rem;
                font-weight: bold;
                color: $font-color;
                word-wrap: break-word;
                word-break: break-word;
            }
            p {
                text-align: left;
                color: #a4a5aa;
            }
            span {
                font-size: 14px;
                font-weight: 600;
                color: #464343;
                text-transform: uppercase;
            }
            .buttons-wpapper {
                margin-bottom: 5px;
                display: flex;
                width: 100%;
                .image-wrapper {
                    $icon-size: 45px;
                    margin-right: 20px;
                    flex: 0 0 auto;
                    width: $icon-size;
                    height: $icon-size;
                    overflow: hidden;
                    border-radius: $icon-size;
                    background-color: #fae6e8;
                    transition: all 0.1s linear;
                    cursor: pointer;
                    img {
                        padding: 12px;
                    }
                    &:hover {
                        background-color: #f1cace;
                    }
                }
            }
        }
        @media (max-width: 1199.98px) {
            flex-wrap: wrap;
            .person-image-group {
                margin-bottom: 15px;
            }
        }
    }
    .event-info-container {
        padding: 0 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        @media (max-width: 991.98px) {
            border-left: 1px solid transparent;
        }
        @media (max-width: 575.98px) {
            border-right: 1px solid transparent;
        }
        .event-info {
            h3 {
                margin: 3px 0;
                width: 100%;
                font-size: 24px;
                font-weight: bold;
                color: $font-color;
            }
            p {
                margin-bottom: 8px;
                text-align: left;
                color: #a4a5aa;
                font-size: 18px;
            }
            span {
                font-size: 14px;
                font-weight: 600;
                color: #464343;
                text-transform: uppercase;
            }
        }
    }
    .search-container {
        margin: auto auto 0 auto;
        align-items: flex-start !important;
        .dropdown-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            .dropdown {
                max-width: 270px;
                @media (max-width: 767.98px) {
                    // margin-right: 15px;
                    max-width: 100% !important;
                    width: 100%;
                }
            }
            .button {
                margin: 0;
                flex-direction: row-reverse;
                background-color: #464343;
                img {
                    margin: 0 10px 0 0;
                }
                &:hover {
                    background-color: #333;
                }
                @media (max-width: 575.98px) {
                    width: 54px;
                }
            }
        }
    }
}