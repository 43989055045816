@import "../../../variables";
.modal {
    .modal-content {
        border: none;
        border-radius: 38px;
        box-shadow: 0 36px 44px 0 rgba($color: #0b0919, $alpha: 0.43);
        .modal-header {
            border-bottom: 0;
        }
        .feedback-buttons {
            display: block !important;
            padding-top: 0;
            border: none;
        }
    }
    button + span {
        color: red;
    }
}

.feedback-pop-up {
    .modal-dialog {
        max-width: fit-content;
    }
    
    @media (max-width: 600px) {
        .modal-dialog {
            max-width: none;
        }
        
        .header-container {
            width: min-content;
        }
    }
}
