@import "../../../variables";
.header-container {
    margin: 0 auto;
    padding: 0;
    border: none;
    max-width: 580px;
    h1 {
        font-size: 1.875rem;
        font-weight: bold;
        line-height: 1.5em;
        @media (max-width: 991.98px) {
            font-size: 1.75rem;
            line-height: 1.4em;
        }
        @media (max-width: 575.98px) {
            font-size: 1.5rem;
            line-height: 1.3em;
        }
    }
    p {
        font-size: 1rem;
        @media (max-width: 991.98px) {
            font-size: 1rem;
        }
    }
}