@import "../../../variables";
$form-width: 270px;
.gift-events-modal {
    display: none;
    margin: 0 auto;
    padding: 15px 0 30px 0;
    &.active {
        display: block;
    }
    .progress-line {
        .progress-point {
            &:nth-child(1) {
                background-color: $progress-checked-color;
                border-color: $progress-checked-color;
                img {
                    display: block;
                }
            }
            &:nth-child(2) {
                background-color: $progress-checked-color;
                border-color: $progress-checked-color;
                img {
                    display: block;
                }
            }
            &:nth-child(3) {
                background-color: $progress-checked-color;
                border-color: $progress-checked-color;
                img {
                    display: block;
                }
            }
            &:nth-child(4) {
                background-color: $progress-checked-color;
                border-color: $progress-checked-color;
                img {
                    display: block;
                }
            }
            &:nth-child(5) {
                background-color: $progress-point-color;
                border-color: $progress-point-color;
                span {
                    display: block;
                }
            }
        }
    }
    .header-container {
        margin: 0 auto;
        margin-bottom: 60px;
        max-width: $form-group-width;
        border: none;
        @media (max-width: 767.98px) {
            margin-bottom: 30px;
        }
        @media (max-width: 575.98px) {
            margin-bottom: 15px;
        }
    }
    .interests-dropdown-container {
        margin: 0 auto;
        label {
            margin-bottom: 5px;
            font-size: 18px;
            font-weight: normal;
        }
    }
    .gifts-group-container {
        margin: 60px auto 15px auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        column-gap: 0;
        row-gap: 15px;
        @media (max-width: 1199.98px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        @media (max-width: 991.98px) {
            margin: auto;
            grid-template-columns: 1fr 1fr;
            width: 80%;
        }
        @media (max-width: 575.98px) {
            width: 100%;
        }
        .gift-item {
            position: relative;
            // margin-left: 13px;
            // margin-right: 13px;
            transition: all 0.2s linear;
            .search-item-container {
                // margin-right: 9px;
                margin-bottom: 0;
                flex-direction: column-reverse;
                span {
                    margin-bottom: 5px;
                    font-weight: 700;
                }
                .search-item-box {
                    .image-wrapper {
                        img {
                            top: 50%;
                            width: 100%;
                        }
                    }
                    .item-check {
                        .image-wrapper {
                            img {
                                width: 100%;
                            }
                        }
                    }
                    @media (max-width: 575.98px) {
                        width: 150px;
                        height: 150px;
                    }
                }
            }
            .optional-input {
                margin: auto;
                width: 174px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                label {
                    margin-bottom: 5px;
                    input {
                        box-shadow: 0 0 12px 0 rgba($color: #000000, $alpha: 0.18);
                        padding: 10px;
                        opacity: 0;
                    }
                }
                @media (max-width: 575.98px) {
                    width: 150px;
                }
            }
            &:hover {
                form {
                    label {
                        input {
                            opacity: 1;
                        }
                    }
                }
            }
            &.add-gift {
                >.search-item-container {
                    .search-item-box {
                        background-color: rgba($color: #000000, $alpha: 0.18);
                        &::before {
                            content: none;
                        }
                        .item-check {
                            display: none;
                        }
                        .image-wrapper {
                            $image-size: 30px;
                            img {
                                padding: 6px;
                                border: 2px solid #3c50e0;
                                border-radius: 30px;
                                background-color: $white;
                                width: $image-size;
                                height: $image-size;
                                top: calc(50% - 15px);
                            }
                            &::after {
                                content: "Add";
                                display: block;
                                position: absolute;
                                top: calc(50% + 15px);
                                left: 50%;
                                transform: translate(-50%, -50%);
                                color: #3c50e0;
                                text-decoration: underline;
                                z-index: 99;
                            }
                            &:hover {
                                &::after {
                                    color: $dark;
                                }
                            }
                        }
                    }
                    span {
                        visibility: hidden;
                    }
                }
            }
        }
    }
    .gift-options-container {
        padding: 0 15px;
        .gift-question-container {
            padding: 30px 0 60px 0;
            border-top: 1px solid $border-color;
            h5 {
                margin-bottom: 30px;
                font-size: 18px;
            }
            form {
                max-width: 560px;
                margin: 30px auto 0 auto;
                label {
                    display: flex;
                    justify-content: center;
                    input {
                        max-width: 560px;
                        width: 100%;
                        padding: 15px;
                    }
                }
                &:first-of-type {
                    margin-top: 30px;
                }
            }
            textarea {
                margin-top: 30px;
            }
        }
    }
    .buttons-container {
        margin: 30px auto 0 auto;
    }
}

.react-datepicker-wrapper {
    .react-datepicker__input-container {
        display: flex;
        justify-content: center;
        .data-picker,
        input {
            margin: 0 auto 10px auto;
            padding: 10px $input-p;
            width: 100%;
            max-width: 174px;
            border-radius: 10px;
            border: 1px solid $border-color;
            font-size: 16px;
            transition: all 0.1s linear;
            @media (max-width: 575.98px) {
                width: 150px;
            }
            &:active {
                color: $dark;
                background-color: $white !important;
            }
            &:hover {
                box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5) !important;
            }
            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1) !important;
            }
            &:focus-visible {
                outline: none;
                box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1) !important;
            }
        }
    }
}

.react-datepicker__month-container {
    z-index: 100;
}