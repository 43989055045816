@import "../../../variables";
.yes-no-modal {
    padding-top: 0;
    .button {
        flex-direction: row-reverse;
        width: 180px !important;
        img {
            margin: 0 10px 0 0;
            width: 16px;
            height: 16px;
        }
    }
    .delete {
        background-color: $button-primary;
        &:hover {
            background-color: $button-primary-hover;
        }
    }
}

.modal-content {
    .modal-header {
        padding: 60px 15px 15px 15px;
        .modal-title {
            margin: 0 auto;
            color: $font-color;
            font-family: 'Spartan', sans-serif;
            font-weight: bold;
            font-size: 32px;
        }
    }
    .yes-no-modal {
        .dropdown {
            margin: 0 auto 30px auto;
            max-width: 330px;
            width: 100%;
        }
        .buttons-wrapper {
            margin: 30px auto 15px auto;
            display: flex;
            justify-content: space-between;
        }
    }
}

.reasons-for-deactivation {
    margin: 0 auto 30px auto;
    padding: 15px 0 5px 0;
    border-bottom: 1px solid $border-color;
    width: 380px;
    h2 {
        margin-bottom: 15px;
        padding-bottom: 10px;
        font-size: 20px;
        border-bottom: 1px solid $border-color;
    }
}