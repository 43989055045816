@import "../../../variables";
.your-connection-modal {
    display: none;
    margin: 0 auto;
    padding: 15px 0;
    max-width: 100%;
    &.active {
        display: block;
    }
    h5 {
        margin: 20px 0 10px 0;
        font-size: 1.125rem;
        font-weight: 600;
        vertical-align: middle;
        span {
            margin-right: 2px;
            padding: 6px;
            border: 1px solid #E3E3E3;
            border-radius: 30px;
            font-size: 0.875;
            vertical-align: 1px;
            color: $font-secondary-color;
        }
    }
    p {
        font-size: 1rem;
        a {
            text-decoration: underline;
        }
        @media (max-width: 991.98px) {
            margin: 0 !important;
            font-size: 1rem;
        }
    }
    .form-container {
        form {
            label {
                margin: 0;
            }
        }
    }
    .header-container {
        max-width: 100%;
        h1,
        p {
            margin: 0 auto 30px auto;
            max-width: $modal-body-width;
        }
    }
    .avatar-box {
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: -16px;
            bottom: -30px;
            border-bottom-left-radius: 38px;
            width: calc(100% + 25px);
            height: 100%;
            background-image: linear-gradient(to bottom, #ffffff, #fbfbfb, #f6f6f6, #f2f2f2, #eeeeee);
            z-index: 0;
            @media (max-width: 991.98px) {
                bottom: -23px;
                width: calc(100% + 32px);
                border-bottom-right-radius: 38px;
            }
        }
        .avatar-container {
            .avatar-image-wrapper {
                padding: 0;
                width: 149px;
                height: 149px;
                background-color: $avatar-bg-grey;
                z-index: 1;
                overflow: hidden;
                &:hover {
                    background-color: rgba($color: #5B5B6E, $alpha: 0.12);
                }
                img {
                    border-radius: 149px;
                }
            }
            @media (max-width: 767.98px) {
                padding: 0;
            }
        }
    }
    .avatar-info {
        padding: 0 55px;
        @media (max-width: 991.98px) {
            margin-top: 50px;
            padding: 0;
            form {
                margin-bottom: 15px;
            }
        }
        .button {
            margin: 5px auto 0 0;
            max-width: 260px;
            @media (max-width: 991.98px) {
                margin: 5px auto 0 auto;
            }
        }
    }
    .data-picker-wrapper {
        position: relative;
        .base-tooltip-age-range {
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
            @media (max-width: 575.98px) {}
        }
    }
}