@import "../../../variables";
.tour-container {
    .row {
        display: flex;
        align-items: center;
        min-height: 960px;
        height: 100%;
        .image-wrapper {
            margin: auto;
            $imgae-size: 680px;
            width: $imgae-size;
            max-width: 100%;
            height: $imgae-size;
            max-height: 100%;
        }
    }
    .line-path {
        position: relative;
        &::before {
            content: "";
            display: block;
            position: absolute;
            height: 100%;
            width: auto;
            border-right: 3px solid $border-color;
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            border-right: 3px solid $border-color;
            border-bottom: 3px solid $border-color;
        }
    }
    .tour-content-wrapper {
        $step-box-size: 60px;
        display: flex;
        position: relative;
        .decoration {
            position: relative;
            .tour-step {
                display: flex;
                align-items: center;
                justify-content: center;
                width: $step-box-size;
                min-width: $step-box-size;
                height: $step-box-size;
                background-color: $button-primary;
                border-radius: $step-box-size;
                font-size: 28px;
                font-weight: bold;
                color: $white;
                transition: background-color 0.2s;
                cursor: pointer;
                &:hover {
                    background-color: $button-primary-hover;
                }
                span {
                    color: $white;
                }
            }
        }
        .tour-text {
            margin: 8px 30px 0 30px;
            max-width: 330px;
            width: 100%;
            h1 {
                margin-bottom: 30px;
                font-size: 48px;
                text-align: left;
            }
            p {
                margin-bottom: 30px;
                text-align: left;
            }
        }
    }
    @media (max-width: 1199.98px) {
        .row {
            padding: 15px 0;
            min-height: 380px !important;
            .image-wrapper {
                margin: 15px 0;
                height: 380px;
                img {
                    width: 90%;
                }
            }
        }
        .tour-content-wrapper {
            $step-box-size: 45px;
            .decoration {
                position: relative;
                .tour-step {
                    margin-bottom: 15px;
                    font-size: 24px;
                    width: $step-box-size;
                    min-width: $step-box-size;
                    height: $step-box-size;
                }
            }
            .tour-text {
                margin: 0 0 0 15px;
                max-width: 330px;
                width: auto;
                h1 {
                    margin: 12px 0;
                    font-size: 26px;
                    text-align: left;
                }
                p {
                    margin-bottom: 30px;
                    text-align: left;
                }
            }
        }
    }
    @media (max-width: 767.98px) {
        .container {
            max-width: 100% !important;
        }
        .row {
            padding: 15px 0;
            min-height: 100% !important;
            .image-wrapper {
                margin: 15px 0;
                height: 240px;
                img {
                    width: 90%;
                }
            }
        }
        .tour-content-wrapper {
            .tour-text {
                margin: 0 0 0 15px;
                max-width: 330px;
                width: auto;
                h1 {
                    margin: 12px 0;
                    font-size: 26px;
                    text-align: left;
                }
                p {
                    margin-bottom: 30px;
                    text-align: left;
                }
            }
        }
    }
    @media (max-width: 575.98px) {
        .row {
            min-height: 100% !important;
            .image-wrapper {
                margin: 15px 0;
                height: 305px;
                img {
                    width: 80%;
                }
            }
        }
        .line-path {
            position: relative;
            &::before {
                content: none;
            }
            &::after {
                content: none;
            }
        }
        .tour-content-wrapper {
            flex-direction: column !important;
            justify-content: center;
            align-items: center;
            .tour-text {
                margin: auto;
                h1 {
                    text-align: center;
                }
                p {
                    text-align: center;
                }
            }
        }
    }
}