@import "../../variables";
.policy-container {
    padding: 60px 0 30px 0;
    h1 {
        font-size: 3rem;
        margin: 30px 0 60px 0;
    }
    h2 {
        font-size: 1.375rem;
        margin: 15px 0 5px 0;
    }
    p {
        text-align: left;
        font-size: 16px;
    }
    span {
        line-height: 3em;
        font-style: italic;
        color: $font-color;
    }
    @media (max-width: 767.98px) {
        padding: 0;
        h1 {
            font-size: 1.75rem;
            margin: 15px 0 30px 0;
        }
        h2 {
            font-size: 1rem;
            margin: 15px 0 5px 0;
        }
        p,
        span,
        ul,
        li {
            text-align: left;
            font-size: 0.875rem;
        }
    }
}
