@import "../../variables";
.new-gifts-page {
    overflow: hidden;
    .container-fluid {
        padding: 0;
    }
    .profile-container {
        padding: 30px 0;
        background-color: $search-bg;
        .profile-info-container {
            .person-image-group {
                .image-wrapper {
                    &:last-child {
                        display: none;
                    }
                }
            }
            .profile-info {
                .buttons-wpapper {
                    // justify-content: center;
                    align-items: center;
                    // .image-wrapper {
                    //     margin-right: 10px;
                    // }
                    .send-email-wrapper {
                        display: flex;
                        align-items: center;
                        position: relative;
                        pointer-events: none;
                        &:hover {
                            .tip {
                                opacity: 1;
                            }
                        }
                        .button {
                            padding: 10px 15px;
                            font-size: 0.75rem;
                            position: relative;
                            width: 45px;
                            height: 45px;
                            pointer-events: auto;
                            img {
                                margin: 0;
                            }
                        }
                        .tip {
                            margin-left: 15px;
                            display: flex;
                            position: relative;
                            transition: opacity 0.2s ease-in;
                            opacity: 0;
                            position: absolute;
                            left: 100%;
                            min-width: 330px;
                            z-index: 1;
                            &::after {
                                content: '';
                                position: absolute;
                                left: -8px;
                                width: 0px;
                                height: 0px;
                                border-top: 8px solid transparent;
                                border-bottom: 8px solid transparent;
                                border-right: 8px solid $white;
                            }
                        }
                    }
                }
            }
            @media (max-width: 767.98px) {
                margin-bottom: 15px;
                flex-wrap: nowrap !important;
            }
            @media (max-width: 575.98px) {
                flex-wrap: wrap !important;
                justify-content: center;
                .person-image-group {
                    margin: 0;
                    .image-wrapper {
                        margin: 0;
                    }
                }
                .profile-info {
                    p {
                        text-align: center;
                    }
                    .buttons-wpapper {
                        justify-content: center;
                        .send-email-wrapper {
                            &:hover {
                                .tip {
                                    opacity: 1;
                                    display: flex;
                                }
                            }
                            .tip {
                                position: absolute;
                                min-width: 340px;
                                top: calc(100% + 10px);
                                left: calc(50% - 45px);
                                transform: translateX(-50%);
                                opacity: 0;
                                &::after {
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 767.98px) {
            padding: 15px 0;
        }
    }
}