@import "../../variables";
.nav {
    padding: 15px 45px 25px 45px;
    display: flex;
    position: sticky;
    top: 0;
    background-color: $white;
    opacity: 0;
    animation: navbarLoad 1s forwards;
    animation-delay: 0.5s;
    z-index: 99;
    @keyframes navbarLoad {
        0% {
            transform: translateY(-120px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
    .logo {
        margin: auto 30px auto 0;
        padding: 0 5px;
        cursor: pointer;
        width: 16rem;
        img {
            width: 100%;
            height: auto;
            // @media (max-width: 1199.98px) {
            //     width: 80%;
            // }
        }
        @media (max-width: 1199.98px) {
            margin: auto 0;
        }
        @media (max-width: 575.98px) {
            width: 180px;
        }
    }
    &.scrolled {
        box-shadow: 0 0 30px 0 rgba($color: #000000, $alpha: 0.2);
    }
    &.navbar-transperant {
        position: fixed;
        width: 100%;
        background-color: transparent;
        .nav-list {
            .nav-item {
                color: $white;
                &:hover {
                    padding: 5px 10px;
                    background-color: rgba($color: #fff, $alpha: 0.6);
                    border-radius: 50px;
                }
            }
        }
        &.active {
            background: #c2442e;
            box-shadow: 0 0 25px 5px rgba($color: #c2442e, $alpha: 0.6);
            width: 100%;
            height: auto;
        }
        .menu-icon {
            .navicon {
                background: $white;
                &:before,
                &:after {
                    background: $white;
                }
            }
        }
    }
    .dropdown {
        .dropdown-toggle {
            box-shadow: none;
            &:active {
                box-shadow: none !important;
            }
        }
    }
    .nav-list {
        margin-top: 10px;
        display: flex;
        flex: 1 0 auto;
        align-items: center;
        width: auto;
        max-height: 0;
        transition: all .2s ease-out;
        overflow: visible;
        .nav-buttons-group {
            display: flex;
            margin-left: auto;
            margin-right: 0;
        }
        .nav-item {
            margin-right: 10px;
            padding: 5px 10px;
            width: auto;
            color: $dark;
            border-radius: 30px;
            border: 1px solid transparent;
            text-decoration: none;
            text-transform: uppercase;
            cursor: pointer;
            transition: all 0.2s ease-in;
            &:hover {
                color: $button-primary-hover;
            }
            &.active {
                color: $button-primary;
                border: 1px solid $button-primary-hover;
            }
        }
        @media (max-width: 991.98px) {
            // width: 100%;
        }
        @media (max-width: 767.98px) {
            overflow: hidden;
            .nav-item {
                margin: 0 auto;
            }
        }
    }
    .button {
        margin: 0;
        margin-left: auto;
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        width: 140px;
        max-width: 230px;
        padding: 12px;
        text-transform: none;
        background-color: $button-primary;
        img {
            margin-left: 0;
            margin-right: 10px;
            width: 16px;
        }
        &:hover {
            background-color: $button-primary-hover;
        }
        @media (max-width: 767.98px) {
            margin: 0 auto;
        }
    }
    .subscribe-btn {
        margin-left: 10px;
    }
    .menu-icon {
        margin-left: auto;
        padding: 20px 0;
        display: flex;
        position: relative;
        top: 5px;
        user-select: none;
        cursor: pointer;
        .navicon {
            background: $button-primary;
            display: block;
            height: 3px;
            border-radius: 5px;
            position: relative;
            transition: background-color .2s ease-in;
            width: 30px;
            &::before,
            &::after {
                background: $button-primary;
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                transition: all .2s ease-in;
                width: 100%;
            }
            &::before {
                top: 8px;
            }
            &::after {
                top: -8px;
            }
        }
    }
    .menu-btn {
        display: none;
        &:checked~.nav-list {
            max-height: 100%;
            overflow: visible;
            transition: all .2s ease-out;
        }
        &:checked~.nav-list.close {
            max-height: 0;
        }
        &:checked~.menu-icon {
            .navicon {
                background: transparent;
                &::before {
                    transform: rotate(-45deg);
                }
                &::after {
                    transform: rotate(45deg);
                }
            }
        }
        &:checked~.menu-icon:not(.steps),
        &:checked~.menu-icon:not(.steps) {
            .navicon {
                &:before,
                &:after {
                    top: 0;
                }
            }
        }
    }
    @media (max-width: 1199.98px) {
        padding: 15px 20px;
    }
    @media (max-width: 767.98px) {
        padding: 15px 20px 15px 20px;
        .nav-list {
            width: 100%;
            flex-direction: column;
            .nav-item {
                margin-bottom: 15px;
            }
        }
        &.navbar-transperant {
            .nav-list {
                background-color: #c2442e;
                box-shadow: 0 0 30px 0 rgba($color: #000000, $alpha: 0.2);
                border-radius: 10px;
                width: 100%;
                flex-direction: column;
                .nav-item {
                    margin-bottom: 15px;
                    &:first-child {
                        margin-top: 15px;
                    }
                }
                .button {
                    margin-bottom: 15px;
                }
            }
            &.scrolled {
                .nav-list {
                    background-color: transparent;
                    box-shadow: none;
                }
            }
        }
    }
    @media (min-width: 767.98px) {
        .nav-list {
            max-height: none;
        }
        .menu-icon {
            display: none;
        }
    }
    @media (max-width: 575.98px) {
        padding: 15px 20px 15px 20px;
        .nav-list {
            .nav-buttons-group {
                display: flex;
                flex-direction: column-reverse;
                margin-left: auto;
                margin-right: auto;
                .subscribe-btn {
                    margin: 0 auto 1rem auto;
                }
            }
        }
    }
}