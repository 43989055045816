@import "../../variables";
.dropdown-object {
    margin-bottom: 0px !important;
    width: 100%;
    .dropdown-toggle {
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: $white;
        color: $dark;
        border-color: $border-color;
        border-radius: 10px;
        &::after {
            $arrow-size: 10px;
            display: inline-block;
            position: relative;
            top: -2px;
            content: "";
            width: $arrow-size;
            height: $arrow-size;
            border: 2px solid $border-color;
            border-left: 0;
            border-bottom: 0;
            transform: rotate(45deg) translateY(2px);
            animation: rotateArrowBack 0.2s forwards;
        }
        @keyframes rotateArrowBack {
            from {
                transform: rotate(135deg) translateY(0px);
            }
            to {
                transform: rotate(45deg) translateY(2px);
            }
        }
        &:active {
            color: $dark;
            background-color: $white;
            box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5) !important;
        }
        &:hover {
            background-color: $white;
            box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5);
        }
        &:focus {
            background-color: $white;
            box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.8);
        }
    }
    .dropdown-menu {
        margin: 8px 0 !important;
        width: 100%;
        box-shadow: 0 12px 24px 0 rgba($color: #070448, $alpha: 0.2);
    }
}

.show {
    >.btn-primary {
        &.dropdown-toggle {
            background-color: $white;
            color: $dark !important;
            box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1) !important;
            &::after {
                animation: rotateArrow 0.2s forwards;
            }
            @keyframes rotateArrow {
                from {
                    transform: rotate(45deg) translateY(2px);
                }
                to {
                    transform: rotate(135deg) translateY(0px);
                }
            }
        }
    }
}
@media (max-width: 767.98px) {
    .dropdown-object
    {
        margin-bottom: 15px !important;
    }
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    color: #3a4256;
    background-color: $white;
    border-color: transparent;
    box-shadow: none;
}