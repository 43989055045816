@import "../../../variables";
.add-connection-pop-up {
    .modal-header {
        .close {
            z-index: 99;
        }
    }
}

.search-results {
    height: 300px;
    overflow: auto;
    overflow-x: hidden;
    transition: 0.1s;
    &.hidden {
        height: 0;
        transition: 0.1s;
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.228);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    .placeholder-image {
        .image-wrapper {
            width: 40% !important;
            display: block;
            margin: auto;
            img {
                left: 0;
                position: relative;
                transform: none;
            }
        }
    }
}

.add-connection-modal {
    display: block;
    margin: 0 auto;
    padding: 0 0 15px 0;
    max-width: 100%;
    .buttons-container {
        margin: 0 auto;
    }
    &.active {
        display: block;
    }
    h1 {
        @media (max-width: 991.98px) {
            font-size: 28px;
        }
    }
    h5 {
        margin: 40px 0 15px 0;
        font-size: 18px;
        font-weight: normal;
        vertical-align: middle;
        span {
            margin-right: 2px;
            padding: 6px;
            border: 1px solid #e3e3e3;
            border-radius: 30px;
            font-size: 14px;
            vertical-align: 1px;
            color: $font-secondary-color;
        }
    }
    .container {
        padding: 0;
    }
    .buttons-container {
        max-width: 690px;
        p {
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: bold;
            a {
                text-decoration: underline;
            }
            @media (max-width: 991.98px) {
                font-size: 16px;
            }
        }
        .button {
            max-width: 333px;
            width: 100%;
            @media (max-width: 991.98px) {
                max-width: 100%;
            }
        }
        @media (max-width: 575.98px) {
            div {
                padding: 0;
                .button {
                    padding: 15px 10px;
                    font-size: 0.875rem;
                }
            }
        }
    }
    .search-container {
        margin: auto auto 15px auto;
        position: relative;
        max-width: 690px;
        form {
            z-index: 99;
            label {
                margin: 0;
                input {
                    padding: 15px;
                }
            }
        }
        .MuiAlert-root {
            margin-top: 5px;
            padding: 0 15px;
            border-radius: 10px;
        }
    }
    .form-container {
        form {
            label {
                margin: 0;
                input {
                    padding: 15px;
                }
            }
        }
    }
    .header-container {
        max-width: 100%;
        h1,
        p {
            margin: 0 auto 15px auto;
            max-width: $modal-body-width;
        }
    }
    .avatar-box {
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: -16px;
            bottom: -66px;
            border-bottom-left-radius: 38px;
            width: calc(100% + 25px);
            height: 100%;
            background-image: linear-gradient(to bottom, #ffffff, #fbfbfb, #f6f6f6, #f2f2f2, #eeeeee);
            z-index: 0;
            @media (max-width: 991.98px) {
                bottom: -23px;
                width: calc(100% + 32px);
                border-bottom-right-radius: 38px;
            }
        }
        .avatar-image-wrapper {
            width: 149px;
            padding: 15px;
            height: 149px;
            background-color: $avatar-bg-grey;
            z-index: 1;
            &:hover {
                background-color: rgba($color: #5b5b6e, $alpha: 0.12);
            }
        }
        .button {
            position: relative;
            max-width: 264px;
            width: 100%;
            z-index: 1;
        }
    }
    .avatar-info {
        padding: 0 55px;
        @media (max-width: 991.98px) {
            margin-top: 50px;
            padding: 0;
            form {
                margin-bottom: 15px;
            }
        }
        .dropdown {
            margin-bottom: 15px;
            .dropdown-toggle {
                padding: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                background-color: transparent;
                color: $dark;
                border-color: $border-color;
                border-radius: 10px;
                &::after {
                    $arrow-size: 10px;
                    display: inline-block;
                    position: relative;
                    top: -2px;
                    content: "";
                    width: $arrow-size;
                    height: $arrow-size;
                    border: 2px solid $border-color;
                    border-left: 0;
                    border-top: 0;
                    transform: rotate(45deg);
                }
                &:active {
                    color: $dark;
                    background-color: transparent;
                }
                &:hover {
                    box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5);
                }
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1);
                }
            }
            .dropdown-menu {
                width: 100%;
                box-shadow: 0 12px 24px 0 rgba($color: #070448, $alpha: 0.2);
            }
        }
    }
}

.import-contacts-container {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    .social-button {
        margin: 0 auto !important;
        padding: 19px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 50% !important;
        min-height: 51px !important;
        background-color: transparent;
        border: 1px solid $button-primary !important;
        color: $button-primary !important;
        border-radius: 30px !important;
        box-shadow: none !important;
        font-weight: 500 !important;
        text-transform: uppercase !important;
        font-size: 14px;
        img {
            padding: 0;
            margin: 0 10px;
            animation: none;
        }
        &:hover {
            color: $button-primary;
            border-color: $button-primary-hover;
            animation: none;
        }
        div,
        span {
            padding: 0 !important;
        }
        @media (max-width: 991.98px) {
            margin: 0 auto 20px auto;
            max-width: 100% !important;
        }
        @media (max-width: 575.98px) {
            padding: 12px 10px !important;
        }
    }
}