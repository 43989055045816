@import "../../variables";
input[type="radio"] {
    display: none;
}

.radio-group {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    input {
        margin-right: 10px;
        cursor: pointer;
    }
    .radio-button {
        $radio-size: 26px;
        margin: 0;
        padding: 0;
        width: $radio-size;
        height: $radio-size;
        position: relative;
        cursor: pointer;
        &::before {
            position: absolute;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 26px;
            height: 26px;
            background: $white;
            border: 2px solid #ccc;
            border-radius: 100%;
        }
    }
    label {
        margin-top: 8px;
    }
    &.active {
        .radio-button {
            &::before {
                background: $button-primary;
                border: 4px solid $button-primary;
            }
        }
    }
}