@import "../../variables";
.subscription-paywall-container {
    padding-top: 2rem;
    display: flex;
    top: 0;
    width: 100%;
    height: 100%;
    background: $white;
    background-image: url("../../assets/images/subscriptions-paywall-bg.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: auto;
    z-index: 999;
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 2rem;
        width: 100%;
        height: 85%;
        background-image: url("../../assets/images/subscriptions-paywall-decor.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto;
        z-index: -1;
    }
    .logo-container {
        margin: 1rem 0 3rem 0;
        .logo {
            margin: 0 auto 2rem auto;
            display: flex;
            width: 250px;
        }
    }
    strong {
        font-size: 1.125rem;
    }
    h1 {
        margin-top: 2.5rem;
        margin-bottom: 2rem;
        font-size: 2rem;
        text-align: left;
        line-height: 1.6em;
    }
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 1.25rem;
        color: $font-secondary-color;
    }
    p {
        margin-bottom: 2rem;
        text-align: left;
    }
    .error-message {
        color: red;
    }
    ul {
        margin-bottom: 2rem;
        padding: 0;
        list-style: none;
        li {
            margin-bottom: 0.5rem;
            padding-left: 2rem;
            position: relative;
            font-size: 1.125rem;
            &::before {
                content: "";
                position: absolute;
                top: 3px;
                left: 0;
                width: 1.25rem;
                height: 1.25rem;
                background-image: url("../../assets/icons/check-green.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
    .subscriptions-content {
        position: relative;
        .image-wrapper {
            position: relative;
            top: -2.25rem;
            height: 420px;
            cursor: default;
            @media (max-width: 767.98px) {
                display: none;
            }
        }
    }
    aside {
        margin-bottom: 2rem;
        padding: 2rem;
        border-radius: 22px;
        background-color: $search-bg;
        .subscriptions-list {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        .button {
            min-width: 100%;
        }
        &.premium {
            margin-top: 2rem;
        }
    }
    @media (max-width:1199.98px) {
        h1 {
            margin-top: 3.5rem;
            font-size: 1.75rem;
        }
        .subscriptions-content {
            .image-wrapper {
                top: -10px;
                width: 100%;
                height: 235px;
            }
        }
    }
    @media (max-width: 991.98px) {
        h1 {
            margin-top: 0;
        }
        .subscriptions-content {
            .image-wrapper {
                top: -10px;
                width: 75%;
                height: 265px;
            }
        }
    }
    @media (max-width: 575.98px) {
        .subscriptions-content {
            p {
                margin-bottom: 0;
            }
        }
        h1 {
            margin-top: 0;
            font-size: 1.625rem;
        }
        aside {
            margin-bottom: 2rem;
        }
    }
}