@import "../../../variables";
.event-container {
    h5 {
        margin: 25px 0;
        font-size: 1.258rem;
        font-weight: bold;
        text-align: center;
    }
    .event-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 15px;
        row-gap: 15px;
        .event-item-container {
            .image-wrapper {
                img {
                    width: auto;
                    height: 100%;
                }
            }
        }
        @media (max-width: 767.98px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

.radio-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    input {
        margin-right: 10px;
        cursor: pointer;
    }
    .radio-button {
        $radio-size: 26px;
        margin: 0;
        padding: 0;
        width: $radio-size;
        height: $radio-size;
        position: relative;
        cursor: pointer;
        &::before {
            position: absolute;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 26px;
            height: 26px;
            background: $white;
            border: 2px solid #ccc;
            border-radius: 100%;
        }
    }
    label {
        margin-top: 8px;
    }
    &.active {
        .radio-button {
            &::before {
                background: $button-primary !important;
                border: 4px solid $button-primary !important;
            }
        }
    }
}

// .btn-group {
//     width: 100%;
//     input {
//         margin: 0;
//         cursor: pointer;
//     }
//     label {
//         @media (max-width: 991.98px) {
//             font-size: 15px;
//         }
//     }
//     .btn {
//         margin-right: 10px;
//         padding: 3px 3px 3px 35px;
//         position: relative;
//         flex: 0 0 auto;
//         max-width: 33.333%;
//         text-align: left;
//         cursor: pointer;
//         &::before {
//             position: absolute;
//             content: '';
//             top: 50%;
//             transform: translateY(-50%);
//             left: 0;
//             width: 26px;
//             height: 26px;
//             background: $white;
//             border: 2px solid #ccc;
//             border-radius: 100%;
//         }
//         &.active {
//             &::before {
//                 background: $white;
//                 border: 4px solid $button-primary;
//             }
//         }
//     }
// }
// .btn-primary {
//     padding: 10px 0;
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     color: $dark;
//     background-color: transparent;
//     border-color: transparent;
//     box-shadow: none;
//     &:hover {
//         color: $dark;
//         background-color: transparent;
//         border-color: transparent;
//         box-shadow: none;
//     }
//     &:focus {
//         color: $dark;
//         background-color: transparent;
//         border-color: transparent;
//         box-shadow: none;
//     }
//     &.focus {
//         color: $dark;
//         background-color: transparent;
//         border-color: transparent;
//         box-shadow: none;
//     }
// }
// .btn-primary:not(:disabled):not(.disabled):active,
// .btn-primary:not(:disabled):not(.disabled).active {
//     color: $dark;
//     background-color: transparent;
//     border-color: transparent;
//     box-shadow: none;
// }
// .btn-primary:not(:disabled):not(.disabled):active:focus,
// .btn-primary:not(:disabled):not(.disabled).active:focus {
//     color: $dark;
//     background-color: transparent;
//     border-color: transparent;
//     box-shadow: none;
// }
// .btn-primary:not(:disabled):not(.disabled):active,
// .btn-primary:not(:disabled):not(.disabled).active,
// .show>.btn-primary.dropdown-toggle {
//     color: $dark;
//     background-color: transparent;
//     border-color: transparent;
//     box-shadow: none;
// }
// .btn-primary:not(:disabled):not(.disabled):active:focus,
// .btn-primary:not(:disabled):not(.disabled).active:focus,
// .show>.btn-primary.dropdown-toggle:focus {
//     color: $dark;
//     background-color: transparent;
//     border-color: transparent;
//     box-shadow: none;
// }
input[type="radio"] {
    display: none;
}