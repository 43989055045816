@import "../../variables";
$base-width: 368px;
.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.choose-this-btn {}

.person-card-container {
    padding: 30px 1rem;
    border-bottom: 1px solid $border-color;
    position: relative;
    &:after {
        content: '';
        border-radius: 16px;
        position: absolute;
        top: 10px;
        left: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 20px);
        background-color: transparent;
        transition: background-color 0.2s ease-in;
        cursor: pointer;
        z-index: -1;
    }
    &:hover {
        &:after {
            background-color: $avatar-bg-grey;
        }
    }
    .person-name {
        cursor: pointer;
    }
    .person-image-wrapper {
        cursor: pointer;
    }
    .person-info-container {
        display: flex;
        cursor: pointer;
        .person-image-group {
            position: relative;
            &:hover {
                .delete-button {
                    opacity: 1;
                    z-index: 99;
                }
            }
            .delete-button {
                opacity: 0;
                position: absolute;
                top: 15px;
                img {
                    filter: brightness(0) invert(1);
                }
                width: 100%;
                height: 100%;
                max-width: 30px;
                max-height: 30px;
                align-self: flex-start;
                z-index: 9;
            }
        }
        .person-info {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .person-info-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-right: 15px;
                flex: 0 0 auto;
                max-width: 60%;
                h3 {
                    margin: 0;
                    font-weight: bold;
                    color: $font-color;
                    word-wrap: break-word;
                    word-break: break-word;
                }
                p {
                    margin: 0;
                    color: #a4a5aa;
                    text-align: left;
                    word-wrap: break-word;
                    word-break: break-word;
                    &:nth-child(2) {
                        margin: 0;
                    }
                    &:nth-child(3) {
                        color: $profile-bg;
                    }
                }
                .relationship-status-wrapper {
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    h5 {
                        margin: 5px 0;
                        font-size: 1rem;
                        font-weight: normal;
                        color: #a4a5aa;
                    }
                    p {
                        margin: 0;
                        cursor: default;
                    }
                    .button {
                        margin: 0 0 0 10px;
                        padding: 12px;
                        max-width: 20px;
                        max-height: 20px;
                        opacity: 0;
                        img {
                            width: 12px;
                        }
                        &.save-button {
                            opacity: 1;
                            margin-bottom: 12px;
                            background-color: $button-primary;
                            &:hover {
                                background-color: #2bce0a;
                            }
                        }
                    }
                    &:hover {
                        .button {
                            opacity: 1;
                        }
                    }
                }
                .edit-button {
                    padding: 15px;
                    opacity: 1;
                    width: 100%;
                    height: 100%;
                    max-width: 30px;
                    max-height: 30px;
                    align-self: flex-end;
                    background-color: orange;
                    z-index: 1;
                    img {
                        filter: brightness(0) invert(1);
                        margin-left: 0px !important;
                        width: 16px;
                    }
                    &:hover {
                        background-color: rgb(187, 122, 0);
                    }
                }
                .save-button {
                    padding: 15px;
                    opacity: 1;
                    width: 100%;
                    height: 100%;
                    max-width: 30px;
                    max-height: 30px;
                    align-self: flex-end;
                    background-color: rgb(43, 206, 10);
                    z-index: 1;
                    img {
                        filter: brightness(0) invert(1);
                        margin-left: 0px !important;
                        width: 16px;
                    }
                    &:hover {
                        background-color: rgb(37, 177, 9);
                    }
                }
            }
            .gift-button-container {
                flex: 0 0 auto;
                max-width: 40%;
                padding-right: 30px;
                display: flex;
                justify-content: flex-end;
                // max-width: 200px;
                width: 100%;
                align-items: flex-end;
                border-right: 1px solid $border-color;
                .button {
                    img {
                        animation: none;
                    }
                }
            }
            @media (max-width: 575.98px) {
                .person-info-content {
                    padding-right: 0;
                    flex: 0 0 auto;
                    max-width: 100%;
                }
                .gift-button-container {
                    flex: 0 0 auto;
                    max-width: 100%;
                    justify-content: flex-start !important;
                }
            }
        }
    }
    .other-gift-button-container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: center;
        // opacity: 0.5;
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
        .button {
            margin-right: 0;
            img {
                animation: none;
            }
        }
    }
    .button {
        padding: 15px;
        background-color: $button-primary;
        max-width: 170px;
        img {
            margin: 0;
        }
        &:hover {
            background-color: $button-primary-hover;
        }
    }
}