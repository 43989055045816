@import "../../variables";
$base-width: 368px;
.button {
    margin: 0 auto;
    padding: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: $base-width;
    width: 100%;
    background-color: $button-primary;
    border: none;
    border-radius: 60px;
    transition: all 0.2s ease-in;
    span {
        margin: 0;
        width: auto;
        color: $white;
        font-weight: 500;
        text-transform: uppercase;
    }
    img {
        position: relative;
        margin-left: 10px;
        animation: slideArrowStart 0.2s forwards;
    }
    &:hover {
        background-color: $button-primary-hover;
    }
    &.buy-now-button {
        span {
            margin: 0;
            width: auto;
            color: $white;
        }
    }
    &.arrow-animation {
        img {
            position: relative;
            margin-left: 10px;
            animation: slideArrowStart 0.2s forwards;
        }
        &:hover {
            background-color: $button-primary-hover;
            img {
                animation: slideArrowRight 0.2s forwards;
            }
        }
        @keyframes slideArrowStart {
            from {
                transform: translateX(10px);
            }
            to {
                transform: translateX(0px);
            }
        }
        @keyframes slideArrowRight {
            from {
                transform: translateX(0px);
            }
            to {
                transform: translateX(10px);
            }
        }
    }
    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }
    // @media (max-width: 575.98px) {
    //     width: 100% !important;
    // }
    &.social-button {
        margin: 0 25px 0 0;
        padding: 10px;
        max-width: 270px;
        min-height: 56px;
        flex-direction: row-reverse;
        background-color: transparent;
        border: 1px solid #464343;
        color: #464343;
        text-align: left;
        font-size: 14px;
        line-height: 1.3em;
        img {
            margin: 0 10px;
            animation: none;
        }
        &:hover {
            color: $button-primary;
            border-color: $button-primary-hover;
            animation: none;
        }
        // @media (max-width: 991.98px) {
        //     margin: 0 auto 20px auto;
        //     max-width: 45% !important;
        // }
        @media (max-width: 575.98px) {
            max-width: 100% !important;
        }
    }
    &.reverse {
        flex-direction: row-reverse;
    }
}

.secondary {
    margin: 0 auto;
    padding: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $base-width;
    background-color: transparent;
    border: none;
    color: $white;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 60px;
    border: 1px solid #fff;
    transition: all 0.2s ease-in;
    img {
        position: relative;
        margin-left: 10px;
    }
    &:hover {
        background-color: rgba($color: #fff, $alpha: 0.2);
        img {
            animation-name: move;
            animation-duration: 0.2s;
            animation-fill-mode: forwards;
        }
        @keyframes move {
            0% {
                left: 0;
            }
            100% {
                left: 10px;
            }
        }
    }
    &.disabled {
        opacity: 0.3;
    }
    @media (max-width: 575.98px) {
        width: 100% !important;
    }
}

.tour-button {
    margin: 0;
    background-color: $button-primary;
    &:hover {
        background-color: $button-primary-hover !important;
    }
}