$font-color: #00005b;
$font-secondary-color: #87081b;
$h-color: #363639;
$p-color: #3a4256;
$border-color: #b3bed1;
$border-color-s: #E8EAF2;
$testimonial-color: #ffebeb;
$search-bg: #fff9f9;
$box-shadow: 0 6px 12px 0 rgba( $color: #0B0919, $alpha: 0.2, );
// $button-primary: #3c50e0;
// $button-primary-hover: #5468FE;
// $button-secondary: #87081b;
// $button-secondary-hover: #b32025;
$button-primary: #630a10;
$button-primary-hover: #87081b;
$button-close-color: #5b5b6e;
$profile-bg: #c2081b;
$social-icon-color: #6c6c80;
$avatar-bg-red: rgba( $color: #c2081b, $alpha: 0.08, );
$avatar-bg-grey: rgba( $color: #5b5b6e, $alpha: 0.08, );
$progress-point-color: #c2081b;
$progress-border-color: #ffebeb;
$progress-checked-color: #0bc27d;
$white: #fff;
$dark: #3a4256;
$grey: #A2A2AF;
$avatar-grey:#e7eef8;
$form-group-width: 756px;
$modal-body-width: 560px;
$modal-options-width: 1144px;
$top-navbar-transperant: false;
$input-p: 12px;
$close-btn-size: 54px;
$item-box-size: 150px;