@import "../../variables";
.category-filters-container {
    .button {
        width: 100%;
        background-color: #e8eaf2;
        color: $dark;
        span {
            color: $dark;
        }
        &:hover {
            background-color: #d6d6da;
        }
        &:nth-of-type(2) {
            margin: 20px 0;
            padding: 5px;
            justify-content: flex-end;
            background-color: transparent;
            font-weight: normal;
            text-transform: none;
            text-decoration: underline;
            color: $button-primary;
            &:hover {
                color: $button-primary-hover;
            }
        }
    }
}