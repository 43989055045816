@import "../../../variables";
.modal {
    .modal-content {
        border: none;
        border-radius: 38px;
        box-shadow: 0 36px 44px 0 rgba($color: #0B0919, $alpha: 0.43);
        .modal-footer {
            display: none;
        }
    }
}