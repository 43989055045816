@import "../../variables";
.toogle-wrapper {
    margin: 0 auto 0 auto;
    display: flex;
    border: 2px solid $button-primary;
    border-radius: 30px;
    width: 48%;
    height: 62px;
    .btn-outline-dark {
        display: flex;
        flex: 1 1 100%;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 100%;
        line-height: 1.3em;
        font-weight: 600;
        border-radius: 30px;
        border-color: transparent;
        &:hover {
            color: $button-primary;
            background-color: transparent;
            border-color: transparent;
        }
    }
    .add {
        margin-right: 0.5rem;
        position: relative;
        display: flex;
        width: 15px;
        height: 15px;
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url(../../assets/icons/plus.svg);
            background-repeat: no-repeat;
            filter: invert(7%) sepia(95%) saturate(3138%) hue-rotate(345deg) brightness(40%) contrast(101%);
        }
    }
    .add-profile {
        margin-right: 0.5rem;
        position: relative;
        display: flex;
        width: 15px;
        height: 15px;
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url(../../assets/icons/user-icon-grey.svg);
            background-repeat: no-repeat;
            filter: invert(7%) sepia(95%) saturate(3138%) hue-rotate(345deg) brightness(40%) contrast(101%);
        }
    }
    .btn-check:checked+.btn-outline-dark {
        color: $white;
        box-shadow: 0px 0px 0px 2px $button-primary;
        background-color: $button-primary;
        border-color: $button-primary;
        border-radius: 30px;
        .add,
        .add-profile {
            &::before {
                filter: brightness(0) invert(1);
            }
        }
    }
    .btn-outline-dark:focus {
        box-shadow: none !important;
    }
    .btn-check:checked+.btn-outline-dark:focus {
        box-shadow: 0px 0px 0px 2px $button-primary !important;
    }
    @media (max-width: 1199.98px) {
        width: 70%;
    }
    @media (max-width: 991.98px) {
        width: 100%;
        label {
            font-size: 14px;
        }
    }
    @media (max-width: 575.98px) {
        .add,
        .add-profile {
            display: none;
        }
    }
}

.separator {
    margin: 1.5rem 0 2rem 0;
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
    height: 1px;
    background-color: $border-color;
}