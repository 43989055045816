@import "../../../variables";
.progress-line {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 991.98px) {
        // margin-bottom: 35px;
    }
    .progress-point {
        $progress-point-size: 23px;
        margin-right: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: $progress-point-size;
        height: $progress-point-size;
        background-color: $progress-point-color;
        border: 3px solid $progress-border-color;
        border-radius: 30px;
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: calc(50% - 1px);
            left: calc(100% + 16px);
            transform: translateX(-50%);
            width: 26px;
            border-bottom: 2px solid $progress-border-color;
        }
        img {
            display: none;
        }
        span {
            display: none;
            position: absolute;
            top: 22px;
            color: $font-color;
            cursor: pointer;
            transition: all 0.2s linear;
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
        }
        &:nth-child(1) {
            border-color: $progress-point-color;
        }
        &:nth-child(2) {
            background-color: transparent;
        }
        &:nth-child(3) {
            background-color: transparent;
        }
        &:nth-child(4) {
            background-color: transparent;
        }
        &:last-child {
            margin-right: 0;
            background-color: transparent;
            &::after {
                content: none;
            }
        }
        //TODO: 
        &.active {
            background-color: $progress-point-color;
            border-color: $progress-point-color;
        }
        &.skip {
            background-color: $progress-point-color;
            border-color: $progress-point-color;
            span {
                display: block;
            }
        }
        &.checked {
            background-color: $progress-checked-color;
            border-color: $progress-checked-color;
            img {
                display: block;
            }
        }
    }
}