
@import "../../variables";
.about-container {
    .container-fluid {
        background-color: $search-bg;
        .col {
            margin: 0;
            padding: 0;
        }
        h1 {
            padding: 80px 0 0 0;
            font-size: 3rem;
        }
        p {
            padding-bottom: 30px;
            margin: auto;
            max-width: 750px;
            font-size: 1.25rem;
        }
        .image-wrapper {
            height: 360px;
            img {
                top: 40%;
                width: 100%;
                height: auto;
                @media (max-width: 576px) {
                    width: auto;
                }
            }
        }
    }
    .container {
        padding-top: 60px;
        .content {
            margin: auto;
            max-width: 750px;
        }
        h2,
        p {
            font-size: 1.25rem;
            text-align: left;
        }
        h2 {
            margin-bottom: 0.75rem;
        }
        p {
            margin-bottom: 2rem;
        }
    }
}
