@import "../../variables";
footer {
    margin: 0 auto;
    padding: 50px 0;
    max-width: $form-group-width;
    .logo {
        margin-bottom: 30px;
    }
    p {
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: bold;
    }
    a {
        color: #7f7f80;
        transition: all 0.2s ease-in;
        text-decoration: none !important;
        text-align: center;
        &:hover {
            color: $font-color;
            text-decoration: none;
        }
    }
    .social-icons-container {
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        .social-icon {
            margin-right: 15px;
            &:last-child {
                margin: 0;
            }
        }
    }
    .footer-info {
        margin: auto;
        // max-width: 540px;
    }
    @media (max-width: 575.98px) {
        padding: 30px 0;
        p {
            margin-bottom: 15px;
            font-size: 13px;
            font-weight: 600;
        }
        .social-icons-container {
            margin-bottom: 15px;
        }
        .footer-info {
            a {
                font-size: 12px;
            }
            .col {
                padding: 0;
            }
        }
    }
}
