@import "../../../variables";
.event-item-container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    input {
        width: 174px;
        max-height: 46px;
        text-align: center;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    .verified-badge {
        padding: 5px 10px;
        position: absolute;
        top: 118px;
        background-color: $profile-bg;
        color: $white;
        border-radius: 30px;
        z-index: 1;
    }
    .close-event {
        padding: 15px;
        position: absolute;
        right: 22px;
        // border: 2px solid #e8eaf2;
        background-color: $profile-bg;
        color: #333;
        font-weight: 900;
        max-width: 30px;
        max-height: 30px;
        z-index: 1;
        opacity: 0;
        img {
            margin: 0;
            filter: brightness(0) invert(1);
        }
        &::after {
            padding: 5px;
            content: "Are you sure you want to delete?";
            display: block;
            position: absolute;
            top: -45px;
            left: 50%;
            transform: translateX(-50%);
            width: 270px;
            box-shadow: 0 6px 12px 0 rgba($color: #0B0919, $alpha: 0.2);
            font-size: 16px;
            font-weight: 500;
            text-transform: none;
            font-weight: normal;
            background-color: $white;
            border-radius: 5px;
            opacity: 0;
            z-index: 1;
        }
        &::before {
            content: "";
            position: absolute;
            top: -12px;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid $white;
            z-index: 2;
        }
        &:hover {
            // border: 2px solid $profile-bg;
            color: $button-primary;
            &::after {
                animation: fadeIn 0.5s forwards;
                animation-delay: 0.2s;
            }
        }
        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
    .image-wrapper {
        margin-bottom: 15px;
        $image-size: 76px;
        width: $image-size;
        height: $image-size;
        border-radius: $image-size;
    }
    .radio-group {
        margin-top: 10px;
    }
    .event-name {
        margin-top: 10px;
        text-align: center;
        flex: none;
    }
    &:hover {
        .close-event {
            animation: fadeIn 0.2s forwards;
            animation-delay: 0.5s;
        }
    }
    .delete-button {
        padding: 15px;
        opacity: 0;
        position: absolute;
        left: 20px;
        width: 100%;
        height: 100%;
        max-width: 30px;
        max-height: 30px;
        align-self: flex-start;
        background-color: $button-primary;
        z-index: 1;
        img {
            filter: brightness(0) invert(1);
            margin-left: 0px !important;
        }
        &:hover {
            background-color: $button-primary-hover;
        }
    }
    &:hover {
        .delete-button {
            opacity: 1;
        }
    }
    .edit-button {
        padding: 15px;
        opacity: 0;
        position: absolute;
        right: 20px;
        width: 100%;
        height: 100%;
        max-width: 30px;
        max-height: 30px;
        align-self: flex-end;
        background-color: orange;
        z-index: 1;
        img {
            filter: brightness(0) invert(1);
            margin-left: 0px !important;
            width: 16px;
        }
        &:hover {
            background-color: rgb(187, 122, 0);
        }
    }
    &:hover {
        .edit-button {
            opacity: 1;
        }
    }
}