
.react-tabs {
    display: flex;
    width: 100%;
    height: 400px;
    color: white;
  }
  
  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0;
    padding: 0;
    color: white;
    background-color: #fff9f9;

  }
  
  .react-tabs__tab {
    height: 70px;
    list-style: none;
    padding: 12px 6px;
    cursor: pointer;
    color: #bbb;
    border-bottom: 1px solid gainsboro;

    p {
        text-align: left;
    }
  }
  
  .react-tabs__tab--selected {
    border-left: 4px solid #6eb800;
    color: white;
    background-color: gainsboro;
  }
  
  .react-tabs__tab-panel {
    display: none;
    width: 100%;
    padding: 30px;

    p {
        text-align: left;
    }
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
  
  .react-tabs__tab {
    padding-left: 24px;
  }
  
  .react-tabs__tab--selected {
    padding-left: 21px;
  }
  
  .panel-content {
    text-align: left;
  }
  