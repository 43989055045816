@import "../../variables";
.member-connections-page {
    overflow: hidden;
    .profile-col {
        padding: 0;
    }
    .member-error {
        color: red;
        font-weight: 700;
        font-size: 1.5rem;
    }
    .profile-container-wrapper {
        padding: 0;
    }
    .profile-container {
        padding: 40px 0;
        position: relative;
        background-color: transparent;
        background-image: url(../../assets/images-lg/member-connections-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        .profile-header {
            h6 {
                margin-right: 5px;
                @media (max-width: 575.98px) {
                    margin-right: 0;
                    text-align: center;
                }
            }
        }
        .profile-avatar {
            display: flex;
            flex-direction: column;
            .avatar-container {
                padding: 0;
                flex-direction: row;
                align-items: flex-end;
                justify-content: flex-start;
                width: 100%;
                .avatar-image-wrapper {
                    $avatar-size: 130px;
                    margin: 0;
                    padding: 0;
                    flex: 0 0 auto;
                    width: $avatar-size;
                    height: $avatar-size;
                    overflow: hidden;
                    background-color: rgba($color: #fff, $alpha: 0.5);
                }
                span {
                    margin: 0 0 5px 15px;
                    color: $white;
                    font-size: 30px;
                    font-weight: bold;
                }
                @media (max-width: 575.98px) {
                    flex-wrap: wrap;
                }
            }
            >.button {
                padding: 5px;
                margin-left: 145px;
                max-width: 200px;
                background: transparent;
                border: 2px solid $white;
                &:hover {
                    background: rgba($color: #fff, $alpha: 0.1);
                }
                @media (max-width: 575.98px) {
                    margin-top: 15px !important;
                    max-width: 100% !important;
                }
            }
            @media (max-width: 575.98px) {
                .avatar-container {
                    justify-content: center;
                    span {
                        margin: 0 auto 15px auto;
                    }
                }
                >.button {
                    margin: auto;
                    padding: 15px;
                    max-width: 270px;
                }
            }
        }
        .profile-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            .rapport-counter {
                margin-bottom: 30px;
            }
            .button {
                margin: 0;
                padding: 15px;
                max-width: 270px;
                background-color: transparent;
                border: 2px solid $white;
                &:hover {
                    background-color: rgba($color: #fff, $alpha: 0.1);
                }
                @media (max-width: 575.98px) {
                    max-width: 100% !important;
                }
            }
        }
    }
    .social-connections-container {
        margin-top: 60px;
        margin-bottom: 60px;
        .social-buttons-group {
            display: flex;
            flex-wrap: wrap;
            form {
                margin: 0;
                cursor: not-allowed;
                margin-bottom: 30px;
                label {
                    margin: 0;
                    cursor: not-allowed;
                    input {
                        padding: 15px 40px 15px 15px;
                    }
                }
            }
            @media (max-width: 767.98px) {
                margin-bottom: 20px;
            }
        }
        .add-buttons-group {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            .button {
                margin-right: 0;
                max-width: 270px;
                @media (max-width: 1199.98px) {
                    max-width: 220px;
                }
                @media (max-width: 991.98px) {
                    margin-right: auto;
                    max-width: 45% !important;
                }
                @media (max-width: 767.98px) {
                    max-width: 300px !important;
                }
                @media (max-width: 575.98px) {
                    max-width: 100% !important;
                }
            }
        }
        .button {
            padding: 15px;
            width: 100%;
        }
    }

    .search-connections-container {
        margin-top: 30px;
        padding: 0 0 30px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $border-color;
        form {
            label {
                margin: 0;
            }
        }
        .button {
            margin-left: auto;
            margin-right: 0;
            max-width: 270px;
            flex-direction: row-reverse;
            img {
                margin: 0 10px 0 0;
                width: 22px;
                filter: brightness(0) invert(1);
            }
        }
        .create-profile {
            position: relative;
            .button {
                position: relative;
                z-index: 9;
                img {
                    width: 16px;
                }
            }
            &.no-connections {
                .button {
                    position: relative;
                    &::after {
                        content: '';
                        border: 4px solid $button-primary;
                        border-radius: 60px;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        // right: 12px;
                        top: 0;
                        animation: pulsate 1.5s ease-out infinite;
                        opacity: 0.0;
                    }
                }
            }
        }
        @keyframes pulsate {
            0% {
                transform: scale(0.9, 0.75);
                opacity: 0.0;
            }
            40% {
                opacity: 0.80;
            }
            100% {
                transform: scale(1.12, 1.35);
                opacity: 0.0;
            }
        }
        @media (max-width: 767.98px) {
            margin: 30px 0 0 0;
            form {
                margin-bottom: 30px;
            }
            .button {
                margin-bottom: 15px;
                max-width: 100% !important;
            }
        }
    }
    .persons-container {
        margin-bottom: 120px;
        padding-top: 30px;
        .placeholder-image {
            margin: 30px 0;
            .image-wrapper {
                width: 20% !important;
                display: block;
                margin: auto;
                img {
                    left: 0;
                    position: relative;
                    transform: none;
                }
            }
        }
        @media (max-width: 575.98px) {
            padding: 0;
        }
    }
    .search-container {
        position: relative;
        padding: 0 20px 60px 20px;
        background-color: $search-bg;
        &::after {
            content: "";
            display: block;
            background-color: $search-bg;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 100%;
        }
        form {
            margin-top: 60px;
            margin-bottom: 30px;
            max-width: 344px;
            label {
                margin: 0;
                input {
                    padding: 15px 40px 15px 15px;
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 1199.98px) {
        .container {
            max-width: 100%;
            .page-wrapper {
                flex-direction: column-reverse;
                .search-container {
                    padding: 0 20px;
                    form {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
    @media (max-width: 1199.98px) {}
    @media (max-width: 991.98px) {}
    @media (max-width: 767.98px) {
        .social-connections-container {
            .social-buttons-group {
                .button {
                    margin: auto;
                    margin-bottom: 15px;
                    max-width: 300px;
                }
            }
            form {
                margin: auto !important;
                max-width: 300px;
            }
            .button {
                margin: auto;
                margin-bottom: 15px;
                max-width: 300px;
            }
        }
        .persons-container {
            .person-info-container {
                margin-bottom: 30px;
                padding-bottom: 30px;
                border-bottom: 1px solid rgba($color: $border-color, $alpha: 0.5);
                .person-info {
                    .gift-button-container {
                        border: none;
                        padding: 0;
                        .button {
                            margin: 0;
                        }
                    }
                }
            }
            .other-gift-button-container {
                align-items: center;
                .dropdown {
                    margin: 0;
                    width: 300px;
                }
                .button {
                    margin-right: 0;
                }
            }
        }
    }
    @media (max-width: 575.98px) {
        .profile-container {
            .profile-avatar {
                margin: 0;
            }
            .profile-info {
                margin-top: 15px;
                align-items: center;
            }
        }
        .persons-container {
            .person-info-container {
                margin-bottom: 30px;
                padding-bottom: 30px;
                border-bottom: 1px solid rgba($color: $border-color, $alpha: 0.5);
                .person-info {
                    flex-direction: column;
                    justify-content: center;
                    .gift-button-container {
                        border: none;
                        padding: 0;
                        justify-content: flex-end;
                        .button {
                            margin: 0;
                        }
                    }
                }
            }
            .other-gift-button-container {
                align-items: center;
                .dropdown {
                    margin: 0 0 0 0;
                    width: 80%;
                }
                .button {
                    max-width: 56px;
                }
            }
        }
    }
}

.async-search-select {
    .css-yk16xz-control,
    .css-1pahdxg-control {
        height: 56px;
        border-radius: 10px;
        cursor: pointer;
    }
    .css-yk16xz-control {
        &:hover {
            box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5) !important;
        }
    }
    .css-1pahdxg-control {
        color: $dark;
        background-color: $white !important;
        box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1) !important;
        border: 1px solid $border-color;
        &:hover {
            box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1) !important;
        }
    }
}

.connection-type {
    text-align: left !important;
    color: white;
    width: fit-content;
    padding: 6px 12px 6px 12px;
    border-radius: 8px;
    font-weight: 500;
    margin-top: 5px;
    &.real {
        background-color: green;
    }
    &.placeholder {
        background-color: indianred;
    }
}