@import "../../../variables";
$item-size: 150px;
$check-size: 36px;
$check-space: 8px;
.search-item-container {
    margin: auto;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: all 0.2s linear;
    cursor: pointer;
    .search-item-box {
        margin: auto;
        margin-bottom: $check-space;
        overflow: hidden;
        position: relative;
        background-color: #ffeded;
        border: 2px solid $white;
        border-radius: 24px;
        width: $item-box-size;
        height: $item-box-size;
        .item-check {
            padding: $check-space;
            position: absolute;
            top: $check-space;
            right: $check-space;
            width: $check-size;
            height: $check-size;
            background-color: rgba($color: $white, $alpha: 0.28);
            border: 2px solid $white;
            border-radius: 30px;
            z-index: 1;
            .image-wrapper {
                display: none;
            }
        }
        .image-wrapper {
            img {
                width: 146px;
                height: 146px;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    span {
        width: 100%;
        font-size: 1rem;
        color: $font-color;
        font-weight: bold;
        text-align: center;
    }
    .event-exists-span {
        font-style: italic;
        font-size: 14px;
        color: $font-color;
    }
    &:not(.item-added):hover {
        .search-item-box {
            background-color: $white;
            border: 2px solid $button-primary-hover;
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 18px;
                right: 16px;
                width: 21px;
                height: 21px;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url(../../../assets/icons/checking-mark-hover.svg);
                z-index: 99;
            }
        }
    }
    &.item-added {
        .event-exists-span {
            position: relative;
            top: -5px;
            color: $font-secondary-color;
            font-style: normal;
        }
        .search-item-box {
            border: 2px solid $button-primary;
            .item-check {
                border: 2px solid $white;
                background-color: $button-primary;
                .image-wrapper {
                    display: block;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
    &.selected {
        .search-item-box {
            background-color: $white;
            border-color: $button-primary;
            .item-check {
                background-color: $button-primary;
            }
            .image-wrapper {
                display: block;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            &:hover {
                &::before {
                    content: none;
                }
            }
        }
    }
}

.react-datepicker__input-container {
    z-index: 1;
}