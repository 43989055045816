@import "../../variables";
$login-width: 396px;
$login-mobile-width: 100%;
.set-password {
    margin: 0 auto;
    padding: 15px 0 50px 0;
    max-width: 100%;
    p {
        margin: auto;
        width: $login-width;
        text-align: left;
    }
    a {
        text-decoration: underline;
    }
    .header-container {
        margin: auto;
        width: $login-width;
        h1 {
            font-size: 30px;
        }
        p {
            margin-bottom: 30px;
            text-align: center;
            font-size: 18px;
        }
    }
    .form-container {
        form {
            margin: auto;
            width: $login-width;
        }
    }
    .button {
        width: $login-width;
        &:nth-of-type(1) {
            margin-bottom: 30px;
        }
        &:nth-of-type(2),
        &:nth-of-type(3) {
            margin-bottom: 15px;
            flex-direction: row-reverse;
            border: 1px solid $border-color;
            color: #464343;
            background-color: transparent;
            &:hover {
                border-color: $button-primary-hover;
            }
            img {
                margin: 0 10px 0 0;
                animation-name: move;
                animation-fill-mode: none;
            }
            @keyframes move {
                0% {
                    left: 0;
                }
                100% {
                    left: 0;
                }
            }
        }
        &:nth-of-type(3) {
            margin-bottom: 50px;
        }
        &:nth-of-type(4) {
            background-color: #E8EAF2;
            color: #4C4C5B;
            &:hover {
                background-color: $button-primary-hover;
                color: $white;
            }
        }
    }
    @media (max-width: 767.98px) {
        p {
            width: $login-mobile-width;
        }
        .header-container {
            width: $login-mobile-width;
        }
        .form-container {
            form {
                width: $login-mobile-width;
            }
        }
        .button {
            width: $login-mobile-width;
        }
    }
}