@import "../../variables";
$categories-background: #f9f9fe;
$categories-color: #3c50e0;
;
.nav-container {
    background-color: $categories-background;
    .categories-navbar {
        margin: 0;
        padding: 20px 0;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        background-color: $categories-background;
        // border-top: 1px solid $border-color;
        z-index: 98;
        // &::before,
        // &::after {
        //     content: "";
        //     display: block;
        //     background-color: $categories-background;
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;
        //     top: 0;
        // }
        // &::before {
        //     right: 100%;
        // }
        // &::after {
        //     left: 100%;
        // }
        .logo {
            margin: auto 0 auto 20px;
            display: none;
            align-items: center;
            cursor: pointer;
            img {
                max-width: 20px;
                max-height: 20px;
                width: 20px;
                height: auto;
                @media (max-width: 575.98px) {
                    width: 90%;
                }
            }
        }
        .menu-icon {
            margin: 0 20px;
            padding-right: 40px;
            position: relative;
            color: $categories-color;
            cursor: pointer;
            font-weight: 500;
            text-transform: uppercase;
            transition: all 0.2s;
            .plus {
                position: absolute;
                right: 0;
                width: 23px;
                height: 23px;
                cursor: pointer;
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    background-color: $categories-color;
                    transition: transform 0.25s ease-out;
                }
                &:before {
                    top: 0;
                    left: 50%;
                    width: 3px;
                    height: 100%;
                    margin-left: -2px;
                }
                &:after {
                    top: 50%;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    margin-top: -2px;
                }
            }
            &:hover {
                color: $font-color;
            }
        }
        .nav-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            max-height: 0;
            transition: max-height .1s ease-out;
            overflow: hidden;
            .nav-item {
                margin: 15px 0;
                color: $categories-color;
                font-weight: normal;
                padding: 0 20px;
                text-transform: uppercase;
                text-decoration: none;
                text-align: center;
                &:hover {
                    color: $font-color;
                }
            }
        }
        &.open-list {
            .nav-list {
                max-height: 100%;
                background-color: $categories-background;
                overflow: visible;
            }
            .menu-icon {
                color: $font-color;
                .plus {
                    &:before {
                        transform: rotate(90deg);
                    }
                    &:after {
                        transform: rotate(180deg);
                        background-color: $font-color;
                    }
                }
            }
        }
        @media (min-width: 1199.98px) {
            .menu-icon {
                display: none;
            }
            .nav-list {
                max-height: none;
            }
        }
        @media (max-width: 1199.98px) {
            .logo {
                display: flex;
            }
            justify-content: space-between;
        }
    }
}