@import "../../../variables";
.edit-profile-modal {
    .modal-body {
        padding: 15px 0;
        .profile-container {
            padding: 15px 50px;
            .profile-info {
                margin-top: auto;
                width: 100%;
            }
        }
        .edit-profile {
            padding: 0 50px;
            h5 {
                margin: 20px 0 10px 0;
            }
        }
    }
    @media (max-width: 991.98px) {
        .modal-body {
            .profile-container {
                padding: 25px 0 0 0;
            }
            .edit-profile {
                padding: 0;
            }
        }
    }
    @media (max-width: 767.98px) {
        .modal-body {
            .edit-profile {
                .container-fluid {
                    .container {
                        padding: 0;
                    }
                }
            }
        }
    }
}