@import "../../variables";
.profile-login {
    margin: 0 0 0 auto !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    padding: 5px;
    height: 48px;
    background-color: $button-primary;
    border-radius: 30px;
    &:hover {
        background-color: $button-primary-hover;
    }
    .profile-avatar {
        padding-left: 36px;
        display: flex;
        align-items: center;
        position: relative;
        .avatar {
            position: absolute;
            left: 0;
            width: 28px;
            height: 28px;
            border-radius: 30px;
            box-shadow: 0 0 0 2px rgba($color: $white, $alpha: 1.0);
            background-color: rgba($color: $white, $alpha: 0.5);
        }
        .initials-view {
            &.navbar-initilas {
                margin: 0;
                padding-bottom: 2px;
                border: 2px solid $border-color;
                width: 28px;
                height: 28px;
                left: 0;
                transform: translate(0, -50%);
                font-size: 0.875rem;
            }
        }
    }
    &.nav-item {
        &.show {
            .dropdown-toggle {
                &::after {
                    animation: rotateArrow 0.2s forwards;
                }
                @keyframes rotateArrow {
                    from {
                        transform: rotate(45deg)
                    }
                    to {
                        transform: rotate(0deg)
                    }
                }
            }
        }
    }
    .dropdown-toggle {
        padding: 0 25px 0 10px !important;
        background-color: transparent !important;
        p {
            margin: 5px 0 0 0;
            font-size: 12px;
            font-family: 'Spartan', sans-serif;
            font-weight: 500;
            color: $white;
        }
        &::after {
            transform: rotate(0deg);
            left: 5px;
            border: 2px solid $white !important;
            border-left: 0 solid transparent !important;
            border-bottom: 0 solid transparent !important;
        }
        &:active {
            color: $white;
            background-color: transparent !important;
        }
        &:hover {
            box-shadow: none !important;
        }
        &:focus {
            box-shadow: none !important;
        }
    }
    .dropdown-menu {
        margin: 16px 0;
        // transform: translate(0, 55px) !important;
        .dropdown-item {
            display: flex;
            align-items: center;
            &:active {
                a,
                span {
                    color: $dark;
                }
                background-color: $white;
            }
            .image-wrapper {
                margin-right: 10px;
                width: 16px;
                height: 16px;
                overflow: visible;
            }
            a {
                color: $dark;
                text-decoration: none;
            }
            span {
                color: $button-primary;
            }
        }
    }
    @media (max-width: 991.98px) {
        // .profile-avatar {
        //     p {
        //         display: none;
        //     }
        //     .image-wrapper {
        //         margin: 0;
        //     }
        // }
        // .dropdown-toggle {
        //     padding: 0 10px !important;
        //     &::after {
        //         display: none !important;
        //     }
        // }
    }
    @media (max-width: 767.98px) {
        margin: auto !important;
        .profile-avatar {
            p {
                display: block;
            }
            .image-wrapper {
                margin-right: 10px;
            }
        }
        .dropdown-toggle {
            padding: 0 25px 0 10px !important;
            &::after {
                display: block !important;
            }
        }
    }
}