.error-page-container {
    padding: 80px 0 40px 0;
    background-color: #FFFBFA;
    opacity: 0;
    animation: fadeIn 1.5s forwards;
    animation-delay: 0.3s;
    h1 {
        font-size: 2rem;
    }
    p {
        margin: 15px auto 30px auto;
        max-width: 660px;
        font-size: 1rem;
    }
    .image-wrapper {
        margin: 0 auto 30px auto;
        max-width: 280px;
        height: 280px;
    }
    .button {
        max-width: 368px;
    }
    @media (max-width: 1199.98px) {
        padding: 60px 0;
    }
    @media (max-width: 767.98px) {
        h1 {
            font-size: 36px;
        }
        .image-wrapper {
            max-width: 330px;
            height: 330px;
        }
    }
    @media (max-width: 575.98px) {
        h1 {
            font-size: 32px;
        }
        p {
            font-size: 16px;
        }
        .image-wrapper {
            max-width: 230px;
            height: 230px;
        }
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}