@import "../../variables";
.image-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
        object-fit: contain;
    }
}