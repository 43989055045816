@import "../variables";
$top-navbar-transperant:true;
h1 {
    text-align: center;
    color: $font-color;
    font-size: 4.5rem;
    @media (max-width: 767.98px) {
        font-size: 3.75rem;
    }
}

h2 {
    color: $font-color;
    font-size: 3rem;
    font-weight: bold;
}

p {
    text-align: center;
    color: $p-color;
    font-size: 1.125rem;
    @media (max-width: 767.98px) {
        font-size: 1rem;
    }
}

.how-work-article {
    padding: 85px 0 75px 0;
    background-image: linear-gradient(to right, #87071b, #96061c, #a6061c, #b5061c, #c5081b);
    h3 {
        margin-bottom: 30px;
        color: $white;
        font-weight: normal;
    }
    .button {
        width: 368px;
        border: 2px solid $white;
        background-color: transparent;
        img {
            margin-left: 30px;
        }
        &:hover {
            background-color: rgba($color: #fff, $alpha: 0.10);
        }
        @media (max-width: 575.98px) {
            width: 100%;
        }
    }
    @media (max-width: 575.98px) {
        h3 {
            font-size: 1.25rem;
            text-align: center;
        }
    }
}

.personal-assistant-article {
    padding: 170px 0 150px 0;
    overflow: hidden;
    h2 {
        margin-bottom: 30px;
    }
    p {
        text-align: left;
        font-size: 1.5rem;
        color: $p-color;
    }
    .picture-container {
        background-image: url(../assets/images/gift-bg.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        &.gift {
            position: relative;
            width: 100%;
            height: 100%;
            .animated {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 86px;
                height: 86px;
                background-color: $white;
                border-radius: 34px;
                border: 1px solid $border-color;
                box-shadow: 22px 36px 44px 0 rgba($color: #070448, $alpha: 0.13);
                &:nth-child(1) {
                    top: -25px;
                    right: 200px;
                }
                &:nth-child(2) {
                    top: -50px;
                    left: 215px;
                }
                &:nth-child(3) {
                    top: 5px;
                    left: 80px;
                }
                &:nth-child(4) {
                    left: 65px;
                    bottom: 45px;
                }
                &:nth-child(5) {
                    right: 195px;
                    bottom: -25px;
                }
                @media (max-width: 1199.98px) {
                    &:nth-child(1) {
                        top: 35px;
                        left: 255px;
                    }
                    &:nth-child(2) {
                        top: 15px;
                        left: 125px;
                    }
                    &:nth-child(3) {
                        top: 70px;
                        left: 0px;
                    }
                    &:nth-child(4) {
                        left: -5px;
                        bottom: 100px;
                    }
                    &:nth-child(5) {
                        right: 105px;
                        bottom: 45px;
                    }
                }
                @media (max-width: 991.98px) {
                    &:nth-child(1) {
                        top: -30px;
                        left: 415px;
                    }
                    &:nth-child(2) {
                        top: -50px;
                        left: 235px;
                    }
                    &:nth-child(3) {
                        top: 25px;
                        left: 75px;
                    }
                    &:nth-child(4) {
                        top: 255px;
                        left: 55px;
                    }
                    &:nth-child(5) {
                        right: 210px;
                        bottom: -20px;
                    }
                }
                @media (max-width: 767.98px) {
                    &:nth-child(1) {
                        top: -15px;
                        left: 315px;
                    }
                    &:nth-child(2) {
                        top: -25px;
                        left: 155px;
                    }
                    &:nth-child(3) {
                        top: 35px;
                        left: 5px;
                    }
                    &:nth-child(4) {
                        top: 240px;
                        left: -5px;
                    }
                    &:nth-child(5) {
                        right: 130px;
                        bottom: 0px;
                    }
                }
                @media (max-width: 575.98px) {
                    width: 66px;
                    height: 66px;
                    border-radius: 26px;
                    &:nth-child(1) {
                        top: 40px;
                        left: 200px;
                    }
                    &:nth-child(2) {
                        top: 30px;
                        left: 105px;
                    }
                    &:nth-child(3) {
                        top: 70px;
                        left: 15px;
                    }
                    &:nth-child(4) {
                        top: 215px;
                        left: 0px;
                    }
                    &:nth-child(5) {
                        right: 120px;
                        bottom: 50px;
                    }
                }
                .element {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    @media (max-width: 1199.98px) {
        padding: 110px 0 100px 0;
    }
    @media (max-width: 991.98px) {
        .picture-container {
            margin-bottom: 60px;
            height: 400px !important;
        }
    }
    @media (max-width: 767.98px) {
        padding: 80px 0 80px 0;
        h2 {
            font-size: 2.25rem;
        }
        p {
            font-size: 1.125rem;
        }
    }
    @media (max-width: 575.98px) {
        padding: 10px 0 30px 0;
        h2 {
            font-size: 2rem;
        }
        .picture-container {
            margin-bottom: 0;
            height: 400px !important;
            &.gift {
                .element {
                    transform: scale(0.75);
                }
            }
        }
    }
}

.testimonial-article {
    margin-bottom: 150px;
    text-align: center;
    .carousel {
        &.slide {
            height: 100%;
            margin-bottom: 50px;
        }
    }
    .link {
        text-decoration: underline;
    }
    .sr-only {
        display: none;
    }
    @media (max-width: 1199.98px) {
        // TODO
    }
    @media (max-width: 767.98px) {
        .carousel-caption {
            p {
                font-size: 18px !important;
            }
        }
    }
    @media (max-width: 575.98px) {
        margin-bottom: 60px;
        .carousel {
            &.slide {
                margin-bottom: 15px;
                height: 100%;
                padding: 30px 0;
                .carousel-indicators {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.newsletter-article {
    padding: 110px 0;
    text-align: center;
    background-image: radial-gradient(circle, #a1182d, #9a1428, #941024, #8d0c1f, #87081b);
    @media (max-width: 575.98px) {
        padding: 60px 0;
    }
}