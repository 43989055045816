@import "../../variables";
$image-size: 150px;
$icon-size: 45px;
.person-image-group {
    margin-right: 20px;
    padding: 10px 0;
    position: relative;
    .person-image-wrapper {
        position: relative;
        width: $image-size;
        height: $image-size;
        border-radius: $image-size;
        overflow: hidden;
        img {
            width: 100%;
            background-color: $white;
        }
    }
    .image-wrapper {
        flex: 0 0 auto;
        overflow: hidden;
        border-radius: $image-size;
        z-index: 1;
        &:last-child {
            position: absolute;
            top: 115px;
            right: -4px;
            background-color: #fae6e8;
            width: $icon-size;
            height: $icon-size;
            transition: all 0.1s linear;
            cursor: pointer;
            img {
                padding: 12px;
            }
            &:hover {
                background-color: #f1cace;
            }
        }
    }
}

.initials-view {
    padding-bottom: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    max-width: 145px;
    max-height: 145px;
    overflow: hidden;
    border-radius: 150px;
    border: 3px solid $border-color;
    background-color: $avatar-grey;
    color: $p-color;
    font-size: 4rem;
    font-weight: 600;
    user-select: none;
    z-index: 0;
}