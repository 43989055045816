@import "../../../variables";
.buttons-container {
    margin-top: 15px;
    max-width: $modal-body-width;
    .button {
        width: 100%;
        margin-bottom: 15px;
    }
    .cancel {
        .button {
            background-color: #e8eaf2;
            color: $font-color;
            span {
                color: $font-color;
            }
            &:hover {
                background-color: #eeeff7;
            }
        }
    }
}