@import "../../variables";
.gifts-and-search-page {
    .container-fluid {
        padding: 0;
    }
    .profile-container {
        h6 {
            margin-bottom: 50px;
            font-size: 14px;
            color: $white;
            text-transform: uppercase;
        }
        .person-image-group {
            padding: 0;
            .image-wrapper {
                &:last-child {
                    display: none;
                }
            }
        }
        .profile-info-container {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            .profile-info {
                h3 {
                    margin: 0;
                    width: 100%;
                    font-size: 30px;
                    font-weight: bold;
                    color: $font-color;
                }
                p {
                    text-align: left;
                    color: #a4a5aa;
                }
                .buttons-wpapper {
                    margin-bottom: 5px;
                    display: flex;
                    width: 100%;
                    .image-wrapper {
                        $icon-size: 45px;
                        margin-right: 20px;
                        flex: 0 0 auto;
                        width: $icon-size;
                        height: $icon-size;
                        overflow: hidden;
                        border-radius: $icon-size;
                        background-color: #fae6e8;
                        transition: all 0.1s linear;
                        cursor: pointer;
                        img {
                            padding: 12px;
                        }
                        &:hover {
                            background-color: #f1cace;
                        }
                    }
                    .button {
                        margin: 0;
                        padding: 5px 10px;
                        flex-direction: row-reverse;
                        max-width: 350px;
                        border-radius: 10px;
                        text-transform: none;
                        font-weight: normal;
                        justify-content: center;
                        img {
                            margin: 0 10px 0 0;
                        }
                    }
                }
            }
        }
        .search-container {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            .search {
                width: 100%;
                .dropdown-wrapper {
                    display: flex;
                    width: 100%;
                    .dropdown {
                        width: 100%;
                        margin: 0;
                    }
                    .button {
                        $button-size: 56px;
                        margin: 0 0 0 15px;
                        width: $button-size;
                        height: $button-size;
                        border-radius: $button-size;
                        background-color: $profile-bg;
                        img {
                            margin: 0;
                        }
                        &:hover {
                            background-color: $button-primary-hover;
                        }
                    }
                }
            }
        }
    }
    .recommended-products-container {
        .product-card-container {
            .ribbon {
                display: none;
            }
            .image-wrapper {
                width: 250px;
                height: 250px;
            }
            .button {
                display: none;
            }
            .option-buttons-group {
                padding-top: 5px;
                border-top: 1px solid $border-color;
                .button {
                    display: flex;
                }
            }
        }
    }
}