@import "../../variables";
.newsletter {
    margin: 0 auto;
    max-width: $form-group-width;
    .logo {
        margin-bottom: 30px;
    }
    p {
        margin-bottom: 30px;
        color: $white;
    }
    @media (max-width: 575.98px) {
        .form-container {
            padding: 0;
        }
    }
}

// .newsletter .form-container span {
//     left: 0px;
//     bottom: -10px;
//     color: red;
//     padding: 5px;
//     float: left;
//     margin-top: -30px;
//     margin-bottom: 20px;
// }