@import "../../../variables";
.training-wizard-modal {
    margin: auto;
    max-width: 75%;
    height: 100%;
    min-height: 760px;
    @media (max-width: 1199.98px) {
        max-width: 100%;
        min-height: 630px;
    }
    .header-container {
        max-width: 100%;
        h1 {
            margin-bottom: 10px;
            font-size: 1.75rem;
            font-family: "Spartan", sans-serif;
            @media (max-width: 1199.98px) {
                font-size: 1.25rem;
                line-height: 1.4em;
            }
        }
        @media (max-width: 1199.98px) {
            margin: 0 auto 15px auto;
        }
    }
    .variants-container {
        position: relative;
        height: 100%;
        min-height: 560px;
        transition:  all 0.2s;

        @media (max-width: 1199.98px) {
            min-height: 300px;
        }
        .row {
            height: 100%;
            justify-content: center;
        }
        .variant-container {
            margin-bottom: 15px;
            @media (max-width: 1199.98px) {
                padding: 0 15px;
            }
            @media (max-width: 991.98px) {
                &:first-child {
                    margin-bottom: 30px;
                }
            }
            @media (max-width: 575.98px) {
                // margin-bottom: 30px;
                padding: 0;
            }
            .variant-wrapper {
                padding: 0;
                display: flex;
                flex-direction: column;
                // justify-content: space-between;
                position: relative;
                width: 100%;
                height: 100%;
                background-color: $white;
                overflow: hidden;
                border-radius: 22px;
                animation: fadeIn 1s ease-in forwards;
                z-index: 1;
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 2px solid $border-color-s;
                    border-radius: 22px;
                    transition: border-color 0.2s ease-in-out;
                    z-index: 0;
                }
                &:hover {
                    &::after {
                        border-color: $button-primary-hover;
                    }
                }
                .image-wrapper {
                    min-height: 380px;
                    @media (max-width: 1199.98px) {
                       min-height: 300px;
                    }
                    @media (max-width: 575.98px) {
                        min-height: 280px;
                    }
                    img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }
                }
                .variant-context {
                    padding: 20px 20px 30px 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    height: 100%;
                    div {
                        text-align: center;
                    }
                    h2 {
                        font-size: 1.125rem;
                        font-weight: 700;
                        font-family: "Spartan", sans-serif;
                    }
                    span {
                        font-size: 1rem;
                        font-weight: 400;
                        font-family: "Mulish", sans-serif;
                    }
                    h2,
                    span {
                        margin: 0;
                        padding: 0;
                        text-align: center;
                        line-height: 1.6em;
                    }
                    .button {
                        margin: 20px auto 0 auto;
                        padding: 15px;
                        width: 100%;
                        max-width: 320px;
                        z-index: 1;
                    }

                    @media (max-width: 1199.98px) {
                        padding: 15px 15px 20px 15px;
                        .button {
                            margin: 15px auto 0 auto;
                        }
                    }
                }
            }
        }
        .product-placeholder-container {
            margin: 0;
            padding: 0;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            min-height: 560px;
            z-index: 0;
            animation: fadeIn 1s ease-in forwards;
            .wrapper {
                margin: 0;
                padding: 0;
                .product-placeholder-wrapper {
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: calc(100% - 60px);
                    height: calc(100% - 15px);
                    border: 2px solid $border-color-s;
                    background-color: $border-color-s;
                    border-radius: 20px;
                    overflow: hidden;
                    @media (max-width: 1199.98px) {
                        margin: 0 auto;
                        width: calc(100% - 30px);
                        height: calc(100% - 15px);
                    }
                    @media (max-width: 991.98px) {
                        margin: 15px auto;
                        width: calc(100% - 30px);
                        height: calc(100% - 45px);
                    }
                    @media (max-width: 767.98px) {
                        margin: 15px auto;
                        width: calc(100% - 30px);
                        height: calc(100% - 45px);
                    }
                    @media (max-width: 575.98px) {
                        margin: 0 auto;
                        width: 100%;
                        height: calc(100% - 30px);
                    }
                    .image-wrapper {
                        width: 100px;
                        img {
                            filter: opacity(0.2);
                        }
                    }
                    .lds-ring {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        width: 80px;
                        height: 80px;
                    }
                    .lds-ring div {
                        box-sizing: border-box;
                        display: block;
                        position: absolute;
                        width: 45px;
                        height: 45px;
                        margin: 5px;
                        border: 5px solid $button-primary;
                        border-radius: 50%;
                        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                        border-color: $button-primary transparent transparent transparent;
                    }
                    .lds-ring div:nth-child(1) {
                        animation-delay: -0.45s;
                    }
                    .lds-ring div:nth-child(2) {
                        animation-delay: -0.3s;
                    }
                    .lds-ring div:nth-child(3) {
                        animation-delay: -0.15s;
                    }
                    @keyframes lds-ring {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                }
            }
        }
    }
    .button-wrapper {
        .button {
            margin: 0 auto;
            padding: 15px;
            max-width: 315px;
            @media (max-width: 767.98px) {
                &:first-child {
                    margin-bottom: 15px;
                }
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
