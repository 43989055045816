@import "../../variables";
.header-targer-container {
    margin: 0 0 15px 0;
    display: flex;
    align-items: flex-end;
    .header-heading {
        h5 {
            margin: 0 0 15px 5px;
            font-size: 22px;
            font-weight: bold;
            color: $font-color;
            text-transform: uppercase;
        }
        .liked-products {
            margin: 0;
            padding: 15px;
            max-width: 300px;
            background-color: $button-primary;
            color: $white;
            @media (max-width: 767.98px) {
                width: 100% !important;
                text-indent: 0 !important;
            }
        }
    }
    .recommendations-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .recommendations {
            margin: 0;
            padding: 15px;
            max-width: 300px;
            min-width: 54px;
            min-height: 54px;
            flex-direction: row-reverse;
            background-color: #464343;
            img {
                margin: 0 15px 0 0;
            }
            &:hover {
                background-color: #333;
            }
            @media (max-width: 767.98px) {
                width: 54px;
                // text-indent: -99em;
                span {
                    display: none;
                }
                img {
                    margin: 0;
                }
            }
        }
    }
    @media (max-width: 767.98px) {
        .header-heading,
        .recommendations-button {
            padding: 0;
        }
    }
}