@import "../../variables";
.login-form {
    $login-width: 396px;
    margin: 0 auto;
    padding: 15px 0 50px 0;
    max-width: 100%;
    p {
        font-size: 18px;
    }
    a {
        text-decoration: underline;
    }
    label {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        input {
            margin: auto;
            width: $login-width;
        }
    }
    .header-container {
        margin: 0;
        padding: 0;
        border: none;
        h1 {
            font-size: 1.875rem;
        }
    }
    .form-container {
        form {
            margin: auto;
            width: $login-width;
        }
    }
    .invalid-credentials {
        color: #f30909;
    }
    .button {
        background-color: #87081b;
        &:hover {
            background-color: #b32025;
        }
        max-width: $login-width;
        &:nth-of-type(1) {
            margin-bottom: 30px;
        }
        
        &:nth-of-type(3) {
            // margin-bottom: 50px;
            flex-direction: row-reverse;
            border: 1px solid $border-color;
            color: #464343;
            background-color: transparent;
            &:hover {
                border-color: $button-primary-hover;
            }
            img {
                margin: 0 10px 0 0;
                animation-name: move;
                animation-fill-mode: none;
            }
            @keyframes move {
                0% {
                    left: 0;
                }
                100% {
                    left: 0;
                }
            }
        }
        &:nth-of-type(4) {
            background-color: #e8eaf2;
            color: #4c4c5b;
            &:hover {
                background-color: $button-primary-hover;
                color: $white;
            }
        }
    }
    .social-login-wrapper {
        margin-bottom: 60px;
        .google-button,
        .kep-login-facebook {
            margin: 0 auto 15px auto;
            display: flex !important;
            justify-content: center;
            align-items: center;
            position: relative;
            max-width: $login-width;
            width: 100%;
            height: 62px;
            box-shadow: none !important;
            background-color: $white !important;
            border: 1px solid $border-color !important;
            border-radius: 30px !important;
            transition: border-color 0.2s ease-in;
            overflow: hidden;
            padding: 0 !important;
            font-family: 'Spartan', sans-serif !important;
            font-size: 14px;
            font-weight: 600 !important;
            text-transform: uppercase;
            color: #464343;
            &:hover {
                border-color: $button-primary-hover !important;
            }
            &::before {
                display: block;
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
                height: 24px;
                border-radius: 30px;
                background-repeat: no-repeat;
                background-position: center;
            }
            &:active {
                background-color: $white !important;
            }
            >div {
                display: none !important;
            }
            span {
                padding: 0 !important;
                font-family: 'Spartan', sans-serif !important;
                font-weight: 600 !important;
                text-transform: uppercase;
                color: #464343;
            }
        }
        .google-button {
            padding-top: 5px !important;
            padding-left: 24px !important;
            &::before {
                left: 90px;
                background-image: url(../../assets/icons/google-login.svg);
            }
        }
        .kep-login-facebook {
            padding-top: 5px !important;
            padding-left: 24px !important;
            i {
                display: none !important;
            }
            &::before {
                left: 80px;
                background-image: url(../../assets/icons/facebook-login.svg);
            }
        }
        @media (max-width: 575.98px) {
            .google-button,
            .kep-login-facebook {
                &::before {
                    left: 15px;
                }
            }
        }
    }
}