@import "../../variables";
.category-search-bar {
    padding: 30px 0 60px 0;
    .row {
        align-items: center;
    }
    h2 {
        font-size: 36px;
    }
    form {
        label {
            margin: 0;
            input {
                padding: 15px;
            }
        }
    }
    .btn-group {
        .btn {
            margin: 0;
            padding: 0;
            flex: 0 0 50%;
            max-width: 50%;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
            transition: all 0.1s linear;
            opacity: 0.5;
            &::before {
                display: none;
            }
            &:first-of-type {
                padding-right: 15px;
                justify-content: flex-end;
                &::after {
                    content: "/";
                    display: block;
                    position: absolute;
                    top: -1px;
                    right: 3px;
                    opacity: 0.5;
                }
            }
            &:hover {
                opacity: 0.8;
            }
            &.active {
                opacity: 1;
            }
        }
    }
}