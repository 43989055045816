@import "../../../variables";
.welcome-back-modal {
    .button {
        flex-direction: row-reverse;
        // justify-content: flex-end;
        width: 180px !important;
        img {
            margin: 0 10px 0 0;
            width: 16px;
            height: 16px;
        }
    }
    .delete {
        background-color: $button-primary;
        &:hover {
            background-color: $button-primary-hover;
        }
    }
    img {
        width: 60%;
        display: block;
        margin: auto;
    }
}

.modal-content {
    .modal-header.welcome-back-modal {
        .modal-title {
            margin: 0 auto;
            color: $font-color;
            font-size: 40px !important;
            font-family: 'Spartan', sans-serif;
            font-weight: bold;
        }
    }
    .subtitle {
        font-size: 20px !important;
    }
}