@import "../../variables";
.modal {
    .modal-content {
        border: none;
        border-radius: 38px;
        box-shadow: 0 36px 44px 0 rgba($color: #0b0919, $alpha: 0.43);
        .modal-header {
            margin: 0;
            padding: 30px 0 10px 0;
            border-bottom: 0;
            position: relative;
            .btn-close {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                position: absolute;
                left: calc(100% - 40px);
                bottom: calc(100% - 40px);
                height: $close-btn-size;
                width: $close-btn-size;
                font-size: 2.5rem;
                font-weight: normal;
                color: $white;
                text-shadow: none;
                border-radius: 60px;
                background: $button-close-color url(../../assets/icons/cancel-white.svg);
                background-size: 20px;
                background-repeat: no-repeat;
                background-position: center center;
                transition: all 0.1s ease-in;
                opacity: 1;
                span {
                    margin-bottom: 6px;
                    color: $white;
                }
                &:hover {
                    background-color: $dark;
                }
                .sr-only {
                    display: none;
                }
                @media (max-width: 575.98px) {
                    height: $close-btn-size - 10px;
                    width: $close-btn-size - 10px;
                    font-size: 2rem;
                }
            }
        }
        .modal-footer {
            display: none;
        }
    }
}