@import "../../variables";
main {
    padding: 90px 0 80px 0;
    background-image: linear-gradient(to bottom, #ffffff, #fefcfe, #fef9fb, #fff6f7, #fff4f1);
    overflow: hidden;
    animation: fadeIn 3s forwards;
    .main-container {
        position: relative;
        .main-content {
            max-width: $form-group-width;
            h1 {
                margin-bottom: 60px;
                font-weight: bold;
            }
            p {
                margin-bottom: 40px;
            }
            .form-container {
                label {
                    margin-bottom: 30px;
                    input {
                        padding: 19px;
                    }
                }
            }
        }
        .decor {
            position: relative;
        }
    }
    @keyframes fadeIn {
        0% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
    @media (max-width: 1199.98px) {
        .main-container {
            .decor {
                .picture-container {
                    &.first {
                        top: 30%;
                    }
                    &.secound {
                        top: 0;
                        left: -58%;
                    }
                    &.third {
                        right: 8%;
                    }
                    &.fourth {
                        top: -5%;
                        left: 25%;
                    }
                    &.fifth {
                        top: 50%;
                        right: -30%;
                    }
                    &.sixth {
                        top: 27%;
                        left: 0;
                    }
                    &.seventh {
                        left: 15%;
                        bottom: 7%;
                    }
                }
            }
            .main-content {
                margin-bottom: 30px;
            }
        }
    }
    @media (max-width: 991.98px) {
        padding: 0;
        .main-container {
            .decor {
                margin: 30px 0;
                height: 250px;
                .picture-container {
                    &.first {
                        top: 25%;
                        left: 43%;
                    }
                    &.secound {
                        top: 5%;
                        left: 5%;
                    }
                    &.third {
                        right: 8%;
                    }
                    &.fourth {
                        top: 5%;
                        left: 5%;
                    }
                    &.fifth {
                        top: 7%;
                        right: 5%;
                    }
                    &.sixth {
                        top: 0;
                        left: 35%;
                    }
                    &.seventh {
                        left: 58%;
                    }
                }
            }
            .main-content {
                margin-bottom: 30px;
            }
        }
    }
    @media (max-width: 575.98px) {
        .main-container {
            .main-content {
                h1 {
                    margin-bottom: 20px;
                    font-size: 2.25rem;
                }
                .container {
                    padding: 0;
                }
            }
            .decor {
                margin: 30px 0;
                height: 250px;
                .picture-container {
                    &.first {
                        top: 49%;
                        left: 4%;
                    }
                    &.secound {
                        left: 7%;
                    }
                    &.third {
                        right: -10%;
                        bottom: 0;
                    }
                    &.fourth {
                        left: -28%;
                    }
                    &.fifth {
                        top: 45%;
                        right: -7%;
                        .element {
                            bottom: calc(100% - 10px);
                        }
                    }
                    &.sixth {
                        top: 0;
                        left: 35%;
                    }
                    &.seventh {
                        display: none;
                    }
                }
            }
        }
    }
}

// .form-container {
//     //TODO: delete 
//     form {
//         label {
//             margin-bottom: 30px;
//             width: 100%;
//             input {
//                 width: 100%;
//                 padding: 19px;
//                 border-radius: 10px;
//                 border: 1px solid $border-color;
//                 font-size: 16px;
//                 transition: all 0.1s linear;
//                 &:active {
//                     color: $dark;
//                     background-color: transparent;
//                 }
//                 &:hover {
//                     box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5);
//                 }
//                 &:focus {
//                     box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1.0);
//                 }
//                 &:focus-visible {
//                     outline: none;
//                     box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1.0);
//                 }
//             }
//         }
//     }
// }
.picture-container {
    position: absolute;
    .picture {
        position: relative;
        overflow: hidden;
        box-shadow: 22px 36px 44px 0 rgba($color: #070448, $alpha: 0.13);
        z-index: 1;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
        }
    }
    .element {
        width: 100%;
        height: auto;
        z-index: 0;
    }
    &.first {
        top: 15%;
        right: 0;
        opacity: 0;
        animation: scaleIn 2s forwards;
        // animation-delay: 0.2s;
        .animated {
            width: 107px;
            height: 107px;
            .picture {
                width: 100%;
                height: 100%;
                border-radius: 30px 30px 0 30px;
                img {
                    width: calc(100% + 20px);
                }
            }
            .element {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 145px;
                left: -25px;
            }
        }
    }
    &.secound {
        top: 35%;
        left: 60px;
        opacity: 0;
        animation: scaleIn 2s forwards;
        // animation-delay: 0.6s;
        .animated {
            width: 135px;
            height: 135px;
            .picture {
                margin-left: 70px;
                width: 100%;
                height: 100%;
                border-radius: 36px 0 36px 36px;
                img {
                    width: calc(100% + 70px);
                }
            }
            .element {
                position: absolute;
                bottom: calc(100% - 30px);
                left: 0;
                &:last-child {
                    width: 50px;
                    height: 25px;
                    top: calc(100% - 15px);
                    left: calc(100% + 35px);
                }
            }
        }
    }
    &.third {
        right: 0;
        bottom: 5%;
        opacity: 0;
        animation: scaleIn 2s forwards;
        // animation-delay: 1.0s;
        .animated {
            width: 200px;
            height: 200px;
            .picture {
                width: 100%;
                height: 100%;
                border-radius: 46px 0 46px 46px;
                img {
                    width: calc(100% + 120px);
                }
            }
            .element {
                position: absolute;
                left: calc(100% - 30px);
                bottom: calc(100% - 30px);
                width: 50px;
                height: 50px;
            }
        }
    }
    &.fourth {
        top: 15%;
        left: 5%;
        opacity: 0;
        animation: scaleIn 2s forwards;
        // animation-delay: 1s;
        .animated {
            width: 200px;
            height: 200px;
            .picture {
                width: 100%;
                height: 100%;
                border-radius: 46px 46px 46px 0;
                img {
                    left: calc(50% - 25px);
                    width: calc(100% + 100px);
                }
            }
            .element {
                position: absolute;
                left: calc(100% - 25px);
                top: calc(100% - 50px);
                width: 50px;
                height: 100px;
                &:last-child {
                    top: 15px;
                    left: auto;
                    right: calc(100% + 100px);
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
    &.fifth {
        top: 45%;
        right: 60px;
        opacity: 0;
        animation: scaleIn 2s forwards;
        // animation-delay: 0.8s;
        .animated {
            width: 138px;
            height: 138px;
            .picture {
                width: 100%;
                height: 100%;
                border-radius: 36px 36px 36px 0;
                img {
                    width: calc(100% + 90px);
                }
            }
            .element {
                position: absolute;
                left: calc(50% - 15px);
                bottom: calc(100% + 30px);
                width: 50px;
                height: 50px;
            }
        }
    }
    &.sixth {
        top: 63%;
        left: -7%;
        opacity: 0;
        animation: scaleIn 2s forwards;
        // animation-delay: 0.6s;
        .animated {
            width: 129px;
            height: 129px;
            .picture {
                width: 100%;
                height: 100%;
                border-radius: 0 46px 46px 46px;
                img {
                    width: calc(100% + 80px);
                }
            }
            .element {
                position: absolute;
                left: calc(100% - 20px);
                bottom: calc(100% - 40px);
                width: 50px;
                height: 50px;
            }
        }
    }
    &.seventh {
        left: 30%;
        bottom: 0;
        opacity: 0;
        animation: scaleIn 2s forwards;
        // animation-delay: 0.2s;
        .animated {
            width: 113px;
            height: 113px;
            .picture {
                width: 100%;
                height: 100%;
                border-radius: 0 36px 36px 36px;
                img {
                    left: calc(50% + 35px);
                    width: calc(100% + 90px);
                }
            }
            .element {
                position: absolute;
                left: calc(100% - 25px);
                top: calc(100% - 60px);
                width: 50px;
                height: 25px;
                z-index: 2;
            }
        }
    }
    @keyframes scaleIn {
        0% {
            opacity: 0;
            transform: scale(1);
        }
        35% {
            transform: scale(1.1);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.error-message-text-red {
    color: red;
}

.error-message-text-green {
    color: green;
}

.pulsing-button {
    box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
    background-color: #FF0000;
    -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    transition: all 300ms ease-in-out;
}


/* Comment-out to have the button continue to pulse on mouseover */

.pulsing-button:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
    color: #ffffff;
}


/* Animation */

@-webkit-keyframes pulsing {
    to {
        box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
}

@-moz-keyframes pulsing {
    to {
        box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
}

@-ms-keyframes pulsing {
    to {
        box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
}

@keyframes pulsing {
    to {
        box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
}