@import "../../../variables";
.btn-group {
    width: 100%;
    input {
        margin: 0;
        cursor: pointer;
    }
    label {
        @media (max-width: 991.98px) {
            font-size: 15px;
        }
    }
    .btn {
        margin-right: 10px;
        padding: 3px 3px 3px 35px;
        position: relative;
        flex: 0 0 auto;
        max-width: 33.333%;
        text-align: left;
        cursor: pointer;
        &::before {
            position: absolute;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 26px;
            height: 26px;
            background: $white;
            border: 2px solid #ccc;
            border-radius: 100%;
        }
        &.active {
            &::before {
                background: $button-primary;
                border: 4px solid $button-primary;
            }
        }
    }
}

.btn-primary {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $dark;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    &:hover {
        color: $dark;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    &:focus {
        color: $dark;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
    &.focus {
        color: $dark;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }
}

.btn-check:checked+.btn-primary {
    color: $dark;
    background-color: transparent;
    border-color: transparent;
    &::before {
        background: $button-primary;
        border: 2px solid $button-primary;
    }
    &:focus {
        box-shadow: none;
    }
}

// .btn-primary:not(:disabled):not(.disabled):active,
// .btn-primary:not(:disabled):not(.disabled).active,
// .show>.btn-primary.dropdown-toggle {
//     color: $dark;
//     background-color: transparent;
//     border-color: transparent;
//     box-shadow: none;
// }
// .btn-primary:not(:disabled):not(.disabled):active:focus,
// .btn-primary:not(:disabled):not(.disabled).active:focus,
// .show>.btn-primary.dropdown-toggle:focus {
//     color: $dark;
//     background-color: transparent;
//     border-color: transparent;
//     box-shadow: none;
// }
// input[type="radio"] {
//     display: none;
// }