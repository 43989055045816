@import "../../variables";
.gifts-container {
    padding-bottom: 30px;
    // border-bottom: 1px solid $border-color;
    .gifts {
        .header-targer-container {
            .col {
                padding: 0;
            }
            .recommendations {
                max-width: 265px;
                min-width: 54px;
                min-height: 54px;
                background-color: #e8eaf2;
                color: #3c50e0;
                span {
                    color: #3c50e0;
                }
                &:hover {
                    background-color: #d0d8f7;
                }
                @media (max-width: 767.98px) {
                    width: 54px !important;
                    // text-indent: -99em;
                    span {
                        display: none;
                    }
                    img {
                        margin: 0;
                    }
                }
            }
        }
        .gift-events-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .event-item-container {
                max-width: 185px;
                width: 185px;
                margin-bottom: 20px;
                .image-wrapper {
                    $size: 150px;
                    margin: 0;
                    width: $size;
                    height: $size;
                    border-radius: $size;
                }
                .radio-group {
                    .radio-button {
                        display: none;
                    }
                    label {
                        margin-bottom: 0;
                        font-size: 18px;
                        font-weight: 500;
                        color: $font-color;
                    }
                }
                span {
                    color: #a4a5aa;
                }
                @media (max-width: 767.98px) {
                    max-width: 170px;
                }
                @media (max-width: 575.98px) {
                    max-width: 175px;
                }
            }
        }
    }
}