@import "../../variables";
// $product-size: 185px;
$product-size: 380px;
.product-card-container {
    margin: 0 auto 60px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    max-width: 100%;
    h5,
    p,
    span {
        margin: 0;
        width: 100%;
        text-transform: none;
        text-align: center;
        font-family: 'Mulish', sans-serif;
    }
    h5 {
        font-size: 18px;
        color: $font-color;
        font-weight: 700;
    }
    p {
        font-size: 16px;
        color: #6d6d72;
        font-weight: 600;
    }
    span {
        margin-bottom: 15px;
        font-size: 16px;
        color: $profile-bg;
        font-weight: 700;
    }
    .ribbon {
        padding: 6px 15px;
        position: absolute;
        top: 10px;
        left: 10px;
        width: auto;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $white;
        background-color: rgba($color: #211F1F, $alpha: 0.6);
        z-index: 1;
    }
    .image-wrapper {
        margin-bottom: 15px;
        width: 100%;
        height: $product-size;
        transition: all 0.3s;
        >img {
            margin: 0;
            padding: 0;
            object-fit: cover;
            object-position: top;
            object-fit: cover;
            object-position: top;
        }
    }
    .button {
        padding: 14px 5px;
        flex-direction: row-reverse;
        width: 100%;
        max-width: 250px;
        flex: 0 0 100%;
        background-color: $button-primary;
        img {
            margin: 0 15px 0 0;
        }
        &:hover {
            background-color: $button-primary-hover;
        }
    }
    .option-buttons-group {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 250px;
        .button {
            width: 50%;
            flex-direction: row-reverse;
            flex: 0 0 50%;
            background-color: transparent;
            font-size: 14px;
            font-weight: 600;
            span {
                margin: 0;
                width: auto;
                font-size: 14px;
                font-weight: 600;
                color: #6d6d72;
                text-transform: uppercase;
            }
            img {
                margin: 0 10px 0 0;
                width: 14px;
            }
            &:first-of-type {
                flex: 0 0 45%;
                span {
                    color: $button-primary;
                }
                &:hover {
                    span {
                        color: $button-primary-hover;
                    }
                }
            }
            &:last-of-type {
                flex: 0 0 55%;
                justify-content: flex-end;
                span {
                    color: #6d6d72;
                }
                &:hover {
                    span {
                        color: #333;
                    }
                }
            }
        }
    }
    &.hidden {
        display: none;
    }
    @media (max-width: 1399.98px) {
        margin: 0 auto;
    }
}

.recommended-products-container {
    padding: 60px 0 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    &.gift {
        grid-template-columns: 1fr 1fr 1fr;
        .product-card-container {
            .image-wrapper {
                width: 300px;
                height: 300px;
                @media (max-width: 1199.98px) {
                    width: 225px;
                    height: 225px;
                }
            }
        }
        @media (max-width: 991.98px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 767.98px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 575.98px) {
            grid-template-columns: 1fr;
        }
    }
    &.search {
        .product-card-container {
            .image-wrapper {
                width: 100%;
                height: $product-size;
                @media (max-width: 1199.98px) {
                    width: 145px;
                    height: 145px;
                }
            }
        }
        @media (max-width: 1199.98px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media (max-width: 991.98px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 575.98px) {
            grid-template-columns: 1fr;
        }
    }
    .product-card-container {
        .button {
            margin-bottom: 10px;
            width: 100%;
            // display: none;
        }
        .option-buttons-group {
            .button {
                display: flex;
                padding: 5px;
            }
        }
    }
    @media (max-width: 1199.98px) {
        .product-card-container {
            .image-wrapper {
                width: 225px;
                height: 225px;
            }
        }
    }
    @media (max-width: 991.98px) {
        grid-template-columns: 1fr 1fr;
        .product-card-container {
            margin-bottom: 0;
        }
    }
    @media (max-width: 767.98px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 575.98px) {
        grid-template-columns: 1fr;
        .product-card-container {
            .image-wrapper {
                width: 280px;
                height: 280px;
            }
        }
    }
}

.zIndex9 {
    z-index: 9;
}