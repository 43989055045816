@import "../../variables";
$base-width: 368px;
.person-search-result-container {
    padding: 0 15px !important;
    border-bottom: 1px solid $border-color;
    .person-info-container {
        display: flex;
        button {
            width: 60px;
            height: 60px;
            margin: auto;
        }
        &.person-image-group {
            margin-right: 0;
            padding: 15px 0;
            position: relative;
            &:hover {
                .delete-button {
                    opacity: 1;
                }
            }
            .image-wrapper {
                margin: auto 10px auto 0;
                $image-size: 70px;
                $icon-size: 45px;
                flex: 0 0 auto;
                width: $image-size;
                height: $image-size;
                overflow: hidden;
                border-radius: $image-size;
            }
            .delete-button {
                opacity: 0;
                position: absolute;
                top: 15px;
                img {
                    filter: brightness(0) invert(1);
                }
                width: 100%;
                height: 100%;
                max-width: 30px;
                max-height: 30px;
                align-self: flex-start;
                z-index: 9;
            }
        }
        .person-info {
            margin-right: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            h3 {
                margin: 0 10px 0 0;
                padding: 0;
                font-size: 1.25rem;
                font-weight: bold;
                color: $font-color;
            }
            p {
                padding: 0;
                font-size: 1rem;
                color: #a4a5aa;
                text-align: left;
                &:nth-child(2) {
                    margin: 0;
                }
                &:nth-child(3) {
                    color: $profile-bg;
                }
            }
        }
    }
    .options-container {
        display: flex;
        .person-relationship {
            margin: auto 15px auto 0;
            h5 {
                margin: 0 0 5px 0;
                font-size: 0.875rem;
            }
        }
        .button {
            margin: auto 0 15px 0;
            background-color: $button-primary;
            width: 50px;
            height: 50px;
            font-size: 0.875rem;
            img {
                margin: 0;
            }
            &:hover {
                background-color: $button-primary-hover;
            }
        }
    }
    .gift-button-container {
        padding-right: 30px;
        display: flex;
        align-items: flex-end;
        border-right: 1px solid $border-color;
    }
    .other-gift-button-container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: center;
        // opacity: 0.5;
    }
}