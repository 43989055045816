@import "../../variables";
.location-comp {
    input {
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #fff;
        color: #3a4256;
        border: 1px solid #b3bed1;
        border-radius: 10px;
        background-size: 15px;
        background-repeat: no-repeat;
        background-position-x: calc(100% - 12px);
        background-position-y: 50%;
        appearance: none;
        transition: all 0.2s;
        &:hover {
            box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5);
        }
    }
    .select-picker {
        padding: $input-p;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #fff;
        color: #3a4256;
        border: 1px solid #b3bed1;
        border-radius: 10px;
        // background-image: url(../../assets/icons/select-arrow-down.svg);
        background-size: 15px;
        background-repeat: no-repeat;
        background-position-x: calc(100% - 12px);
        background-position-y: 50%;
        appearance: none;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
            box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5);
        }
        &:active,
        &:focus,
        &:focus-visible {
            outline: none !important;
            box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.8);
        }
        option {
            width: 100%;
        }
        @media (max-width: 991.98px) {
            margin-bottom: 15px;
        }
    }
}