@import "../../variables";
.modal {
    &.login-modal {
        display: none !important;
    }
    .modal-dialog {
        &.modal-sm {
            max-width: 500px !important;
        }
    }
    .modal-content {
        border: none;
        border-radius: 38px;
        box-shadow: 0 36px 44px 0 rgba($color: #0B0919, $alpha: 0.43);
        .modal-body {
            .login-form {
                display: block;
                .invalid-credentials {
                    margin-left: 25px;
                    position: relative;
                    bottom: 15px;
                }
            }
            .forgotten-password {
                display: none;
                &.active {
                    display: block;
                }
            }
            .set-password {
                display: none;
                &.active {
                    display: block;
                }
            }
        }
        .modal-footer {
            display: none;
        }
    }
    &.login-modal {
        &.active {
            display: block !important;
        }
    }
}