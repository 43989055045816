@import "../../variables";
.tip {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $font-color;
    background-color: $white;
    box-shadow: $box-shadow;
    border-radius: 10px;
    opacity: 0;
}

.MuiTooltip-tooltip {
    padding: 10px 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white !important;
    box-shadow: $box-shadow;
    border-radius: 10px;
    h6 {
        font-size: 0.875rem;
        font-weight: 400 !important;
        line-height: 1.3em;
        color: $font-color;
        font-family: 'Mulish', sans-serif !important;
    }
    span {
        color: $white !important;
    }
}