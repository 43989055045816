@import "../../../variables";
.feedback-modal {
    .feedback-rating {
        margin: 0 auto 30px auto;
        display: flex;
        justify-content: space-around;
        .image-wrapper {
            width: 80px;
            height: 80px;
            border-radius: 40px;
            border: 2px solid transparent;
            transition: all 0.2s ease-in;
            img {
                padding: 10px;
            }
            &:hover {
                background-color: $search-bg;
            }
            @media (max-width: 575.98px) {
                width: 60px;
                height: 60px;
            }
        }
        .selected {
            .image-wrapper {
                border: 2px solid $button-primary;
                background-color: $search-bg;
            }
        }
    }
    textarea + span {
        color: red;
    }
}
