@import "../../../variables";
.google-fb-missing-data-modal {
    display: none;
    margin: 0 auto;
    padding: 15px 0;
    max-width: 100%;
    &.active {
        display: block;
    }
    h5 {
        margin: 20px 0 12px 0;
        font-size: 1.125rem;
        font-weight: bold;
        vertical-align: middle;
        span {
            margin-right: 2px;
            padding: 6px;
            border: 1px solid #e3e3e3;
            border-radius: 30px;
            font-size: 14px;
            vertical-align: 1px;
            color: $font-secondary-color;
        }
    }
    p {
        font-size: 1rem;
        a {
            text-decoration: underline;
        }
    }
    .header-container {
        max-width: 100%;
        h1,
        p {
            margin: 15px auto;
            max-width: $modal-body-width;
        }
    }
    .avatar-box {
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: -16px;
            bottom: -31px;
            border-bottom-left-radius: 38px;
            width: calc(100% + 25px);
            height: 100%;
            background-image: linear-gradient(to bottom, #ffffff, #fbfbfb, #f6f6f6, #f2f2f2, #eeeeee);
            z-index: 0;
            @media (max-width: 991.98px) {
                bottom: -23px;
                width: calc(100% + 32px);
                border-bottom-right-radius: 38px;
            }
        }
        .avatar-image-wrapper {
            width: 149px;
            padding: 15px;
            height: 149px;
            z-index: 1;
        }
        .button {
            position: relative;
            max-width: 264px;
            width: 100%;
            z-index: 1;
        }
    }
    .avatar-info {
        padding: 0 55px;
        @media (max-width: 1199.98px) {
            padding: 0 15px 0 40px;
        }
        @media (max-width: 991.98px) {
            padding: 0 15px;
        }
        @media (max-width: 575.98px) {
            padding: 0;
        }
    }
    .buttons-container {
        margin: 0;
        max-width: 100%;
        >div {
            padding: 0 15px 0 0;
            @media (max-width: 991.98px) {
                padding: 0;
            }
        }
        h5 {
            padding: 0;
        }
        .button {
            margin: 0 0 15px 0;
            max-width: 100%;
        }
    }
    // TODO: Fix border style
    // input {
    //     padding: 15px;
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     width: 100%;
    //     background-color: #fff;
    //     color: #3a4256;
    //     border-color: #b3bed1;
    //     border-radius: 10px;
    //     background-size: 15px;
    //     background-repeat: no-repeat;
    //     background-position-x: calc(100% - 12px);
    //     background-position-y: 50%;
    //     appearance: none;
    //     transition: all 0.2s;
    //     &:hover {
    //         box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5);
    //     }
    //     &:active,
    //     &:focus,
    //     &:focus-visible {
    //         outline: none !important;
    //         box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.8);
    //     }
    // }
}

//TODO: move to component
// .dropdown {
//     margin-bottom: 15px;
//     .dropdown-toggle {
//         // padding: 15px;
//         // display: flex;
//         // justify-content: space-between;
//         // align-items: center;
//         // width: 100%;
//         background-color: transparent;
//         // color: $dark;
//         // border-color: $border-color;
//         // border-radius: 10px;
//         // &::after {
//         //     $arrow-size: 10px;
//         //     display: inline-block;
//         //     position: relative;
//         //     top: -2px;
//         //     content: "";
//         //     width: $arrow-size;
//         //     height: $arrow-size;
//         //     border: 2px solid $border-color;
//         //     border-left: 0;
//         //     border-top: 0;
//         //     transform: rotate(45deg);
//         // }
//         &:active {
//             color: $dark;
//             background-color: transparent;
//         }
//         &:hover {
//             box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5);
//         }
//         &:focus {
//             box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 1);
//         }
//     }
//     .dropdown-menu {
//         width: 100%;
//         box-shadow: 0 12px 24px 0 rgba($color: #070448, $alpha: 0.2);
//     }
// }
.DatePicker {
    padding: $input-p;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #fff;
    color: #3a4256;
    border-color: #b3bed1;
    border-radius: 10px;
    // background-image: url(../../../assets/icons/select-arrow-down.svg);
    background-size: 15px;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 12px);
    background-position-y: 50%;
    appearance: none;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
        box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.5);
    }
    // &.open {
    //     background-image: url(../../../assets/icons/select-arrow-up.svg);
    // }
    &:active,
    &:focus,
    &:focus-visible {
        outline: none !important;
        box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.8);
    }
    option {
        width: 100%;
    }
    @media (max-width: 991.98px) {
        // margin-bottom: 15px;
    }
}

.select-wapper {
    position: relative;
    &::after {
        $arrow-size: 10px;
        display: inline-block;
        position: absolute;
        top: 40%;
        right: 15px;
        content: "";
        width: $arrow-size;
        height: $arrow-size;
        border: 2px solid $border-color;
        border-left: 0;
        border-bottom: 0;
        transform: rotate(45deg) translateY(2px);
        animation: rotateArrowBack 0.2s forwards;
    }
    &.open {
        &::after {
            animation: rotateArrow 0.2s forwards;
        }
    }
    @keyframes rotateArrowBack {
        from {
            transform: rotate(135deg) translateY(0px);
        }
        to {
            transform: rotate(45deg) translateY(2px);
        }
    }
    @keyframes rotateArrow {
        from {
            transform: rotate(45deg) translateY(2px);
        }
        to {
            transform: rotate(135deg) translateY(0px);
        }
    }
}

input {
    &:active,
    &:focus,
    &:focus-visible {
        outline: none !important;
        box-shadow: 0 0 0 0.2rem rgba($color: $border-color, $alpha: 0.8);
    }
}

.required-modal-field {
    color: red !important;
    font-weight: normal !important;
    text-transform: none !important;
}