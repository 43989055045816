@import "../../variables";
.filters-accordion {
    .filter-group {
        .button {
            margin: 15px 0 0 0;
            padding: 20px 0;
            width: 100%;
            background-color: transparent;
            color: $font-color;
            justify-content: space-between;
            border-radius: 0;
            border-top: 1px solid $border-color;
            span {
                color: $font-color;
            }
            &:hover {
                background-color: transparent;
            }
            img {
                transition: all 0.15s linear;
                transform: rotate(-90deg);
            }
        }
        .price-group {
            display: none;
            align-items: center;
            span {
                padding: 0 10px;
            }
            form {
                label {
                    margin: 0;
                    input {
                        padding: 15px;
                    }
                }
            }
        }
        .category-group {
            margin-bottom: 30px;
            display: none;
            flex-wrap: wrap;
            .checkbox-container {
                width: 100%;
            }
            .button {
                margin: 0;
                padding: 0;
                width: auto;
                border: none;
                text-transform: none;
                border-bottom: 1px solid #3c50e0;
                color: #3c50e0;
                span {
                    color: #3c50e0;
                }
                img {
                    margin-left: 4px;
                    width: 10px;
                    height: auto;
                    top: 1px;
                }
            }
        }
        &.open {
            .button {
                img {
                    transform: rotate(0deg);
                }
            }
            .price-group {
                animation-duration: 0.15s;
                animation: fadeIn 0.15s;
                display: flex;
            }
            .category-group {
                animation-duration: 0.15s;
                animation: fadeIn 0.15s;
                display: flex;
            }
        }
        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
}