@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './variables';

body {
    margin: 0;
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //   sans-serif;
    font-family: 'Spartan', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Spartan', sans-serif;
        font-weight: bold;
    }
    p,
    label,
    span {
        font-family: 'Mulish', sans-serif;
        color: $p-color;
    }
}

code {
    font-family: 'Spartan', sans-serif;
    p,
    label,
    span {
        font-family: 'Mulish', sans-serif;
        color: $p-color;
    }
    // font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    //   monospace;
}